import { Box, Button, Typography } from '@mui/material'
import { AlertCircle, X } from 'react-feather'
import { OfferPendingOffer } from '../../../types/procedureOutputs'
import PendingOffersModal from './PendingOffersModal'
import { useState } from 'react'

type Props = {
  onClose: () => void
  offers: OfferPendingOffer[]
}

const PendingOffersNavbar = ({ onClose, offers }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <Box className="bg-error-500 flex gap-3 rounded-lg px-5 py-1 items-center ">
      <AlertCircle className="text-error-100 h-5 w-5" />
      <Typography className="text_md_medium text-error-25">You have funded deals pending</Typography>
      <Button
        className="bg-white"
        onClick={() => {
          setShowModal(true)
        }}
      >
        Confirm Deals
      </Button>
      <X className="text-error-25 h-5 w-5" onClick={onClose} />
      {setShowModal && <PendingOffersModal offers={offers} open={showModal} setOpen={setShowModal} />}
    </Box>
  )
}

export default PendingOffersNavbar
