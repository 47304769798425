import React from 'react'
import { Box, Modal, ModalProps } from '@mui/material'
import clsx from 'clsx'

export interface DialogProps extends ModalProps {}

export const Dialog = ({ children, className, ...rest }: DialogProps) => {
  return (
    <Modal {...rest} className="flex">
      <Box className="mobile:p-0 mobile:pt-8 p-10 w-full">
        <Box className={clsx('mobile:w-full h-full flex justify-center items-center', className)}>{children}</Box>
      </Box>
    </Modal>
  )
}
