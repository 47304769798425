import { Box, Chip, Typography } from '@mui/material'
import { Link } from '../icons/link'
import clsx from 'clsx'
import { pluralize } from '../utils'
import { OfferStatus, Tier } from '@prisma/client'

export const UserStatusChip = ({ inactive }: { inactive: boolean }) => {
  const activeClass = 'bg-success-50 text-success-800'
  const inactiveClass = 'bg-grey-100 text-grey-500'
  const label = inactive ? 'inactive' : 'active'
  return (
    <Chip
      label={label}
      className={`text_xs_medium tracking-widest w-20 uppercase ${clsx({ [activeClass]: !inactive, [inactiveClass]: inactive })}`}
    />
  )
}

export const LeadsChip = ({
  amount,
  userId,
  handleClick
}: {
  amount: number
  userId: string
  handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}) => {
  return (
    <Chip
      onClick={handleClick}
      className="bg-secondary-50"
      label={
        <Box className="flex gap-1 items-center">
          <Link className="text-secondary-600" fontSize="small" />
          <Typography className="text_sm_medium tracking-widest uppercase text-secondary-600">
            {amount} {pluralize('lead', amount)}
          </Typography>
        </Box>
      }
    />
  )
}

export const OfferChip = ({ status, tier }: { status?: OfferStatus; tier?: Tier }) => {
  const bgcolor =
    status === 'ACCEPTED' || tier === 'TIER_1'
      ? '#F1F8E9 !important'
      : status === 'PENDING' || tier === 'TIER_2'
      ? '#FFFAEB !important'
      : status === 'APPROVED'
      ? '#BBDEFB !important'
      : '#FEE4E2 !important'

  const color =
    status === 'ACCEPTED' || tier === 'TIER_1'
      ? '#558B2F'
      : status === 'PENDING' || tier === 'TIER_2'
      ? '#B54708'
      : status === 'APPROVED'
      ? '#0D47A1'
      : '#B42318'
  const statusText = !status ? undefined : status === 'DENIED' ? 'DECLINED' : status

  return (
    <Chip
      label={statusText || tier?.replace('_', ' ')}
      sx={{ bgcolor, color }}
      className={`text_xs_medium tracking-widest w-24 uppercase py-1 h-fit`}
    />
  )
}

export const PaymentHistoryChip = ({ paymentHistory }: { paymentHistory: boolean }) => {
  const bgcolor = paymentHistory ? '#F1F8E9 !important' : '#FEF3F2 !important'
  const color = paymentHistory ? '#558B2F important' : '#912018 !important'
  return <Chip label={paymentHistory ? 'Yes' : 'No'} sx={{ bgcolor, color }} className={`text_xs_medium tracking-widest w-24 uppercase py-1 h-fit`} />
}

export const OffersAcceptedChip = () => {
  return (
    <Chip
      label="OFFER ACCEPTED"
      className={`text_xs_medium tracking-widest w-fit uppercase py-1 h-fit !text-primary-900 !bg-primary-100`}
    />
  )
}

export const OffersTotalChip = ({ total, status }: { total: number; status: OfferStatus }) => {
  return (
    <Chip
      label={`${total} ${status}`}
      className={`text_xs_medium tracking-widest w-fit uppercase py-1 h-fit ${clsx({
        '!text-warning-700 !bg-warning-100': status === OfferStatus.PENDING,
        '!text-secondary-900 !bg-secondary-100': status === OfferStatus.APPROVED,
        '!text-error-700 !bg-error-100': status === OfferStatus.DENIED
      })}`}
    />
  )
}
export const StageChip = ({
  stageId,
  stageName,
  textColor,
  color
}: {
  stageId: string
  color: string
  stageName: string
  textColor: string | null
}) => {
  return (
    <Box
      key={stageId}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: color,
        borderRadius: '200px',
        py: '4px',
        px: '10px',
				m: '2px'
      }}
    >
      <Typography className="text_sm_medium" color={textColor ?? ''}>
        {stageName}
      </Typography>
    </Box>
  )
}
