import { Lightbulb } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

type DocLinkProps = {
  link: string
}

const DocLink = (props: DocLinkProps) => {
  const { link } = props
  const origin = window.location.origin.includes('localhost') ? 'http://localhost:3000' : window.location.origin
  return (
    <IconButton className="py-0" size="small" onClick={() => window.open(origin + link)}>
      <Lightbulb className="text-sm" />
    </IconButton>
  )
}

export default DocLink
