import { createSvgIcon } from '@mui/material/utils'

export const Subsidiary = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
    <path
      d="M19.3132 18.6927C19.3132 18.6927 16.8714 14.4961 16.4334 13.7434C16.9317 13.5914 17.4626 13.4571 17.6832 13.0763C18.0508 12.4417 17.3964 11.4007 17.5683 10.7205C17.7452 10.0204 18.7901 9.41463 18.7901 8.70303C18.7901 8.00975 17.7099 7.19982 17.5328 6.50386C17.3598 5.82378 18.0121 4.78163 17.6433 4.1478C17.2746 3.51391 16.0462 3.56599 15.5405 3.07943C15.0203 2.57872 15.0219 1.35363 14.3944 1.01807C13.7643 0.681272 12.7402 1.36195 12.0407 1.20071C11.349 1.04132 10.7312 0 10.0087 0C9.27563 0 8.10957 1.18121 7.92669 1.22372C7.22747 1.38627 6.20214 0.707253 5.57271 1.04548C4.94578 1.38216 4.94964 2.60737 4.43032 3.10892C3.92551 3.59637 2.69709 3.54655 2.32943 4.18115C1.96183 4.81564 2.61617 5.85642 2.44441 6.53698C2.27218 7.21926 1.19096 7.91058 1.19096 8.69964C1.19096 9.4113 2.23841 10.0152 2.41647 10.7149C2.58954 11.3949 1.93716 12.437 2.30595 13.071C2.50672 13.4162 2.96225 13.5578 3.41718 13.6941C3.47027 13.7099 3.57069 13.7693 3.50268 13.8684C3.19144 14.4056 0.686386 18.729 0.686386 18.729C0.487099 19.0729 0.648931 19.3677 1.0459 19.3844L2.989 19.4654C3.38597 19.4821 3.88395 19.7706 4.09584 20.1067L5.1327 21.752C5.34459 22.0881 5.6808 22.0817 5.88002 21.7379C5.88002 21.7379 8.78115 16.7293 8.78234 16.7278C8.84055 16.6598 8.89905 16.6738 8.92705 16.6976C9.24447 16.9678 9.68698 17.2375 10.0563 17.2375C10.4185 17.2375 10.7539 16.9837 11.0856 16.7006C11.1126 16.6776 11.1783 16.6304 11.2263 16.7283C11.2271 16.7298 14.125 21.7111 14.125 21.7111C14.3248 22.0545 14.6611 22.0603 14.8723 21.7238L15.9062 20.0766C16.1175 19.7402 16.615 19.4507 17.0119 19.4334L18.9549 19.3487C19.3517 19.3313 19.513 19.0361 19.3132 18.6927ZM13.22 14.1873C11.0716 15.4372 8.49281 15.3033 6.52313 14.0609C3.63698 12.2122 2.68734 8.38793 4.42235 5.39334C6.17717 2.36421 10.0347 1.29536 13.0911 2.95999C13.1071 2.96873 13.123 2.97776 13.1389 2.98668C13.1616 2.99923 13.1842 3.01195 13.2067 3.02479C14.1496 3.56843 14.9673 4.35743 15.5528 5.36379C17.3422 8.43966 16.2958 12.3979 13.22 14.1873Z"
      fill="#724CCA"
    />
    <path
      d="M12.7287 3.92955C12.7198 3.92437 12.7107 3.91956 12.7018 3.91445C11.0691 2.9695 8.98812 2.90042 7.24407 3.9151C4.65596 5.42081 3.77528 8.75131 5.28099 11.3394C5.74044 12.1291 6.37005 12.7596 7.09645 13.212C7.15822 13.2511 7.2207 13.2896 7.28455 13.3266C9.87546 14.8276 13.2042 13.9409 14.7051 11.3501C16.206 8.75921 15.3196 5.43056 12.7287 3.92955ZM13.3768 8.16504L12.4696 9.04928C12.1887 9.32313 12.013 9.86367 12.0794 10.2504L12.2936 11.499C12.3599 11.8857 12.1301 12.0527 11.7828 11.8702L10.6614 11.2806C10.3141 11.098 9.74579 11.098 9.39847 11.2806L8.27718 11.8702C7.92986 12.0527 7.69995 11.8857 7.7663 11.499L7.98045 10.2504C8.0468 9.86367 7.87118 9.32313 7.5902 9.04928L6.68307 8.16504C6.40203 7.8912 6.48991 7.62093 6.87819 7.56445L8.13182 7.38228C8.52011 7.32586 8.97992 6.99179 9.15358 6.63995L9.71422 5.50398C9.88788 5.15214 10.1721 5.15214 10.3457 5.50398L10.9064 6.63995C11.08 6.99179 11.5398 7.32586 11.9281 7.38228L13.1818 7.56445C13.57 7.62093 13.6578 7.8912 13.3768 8.16504Z"
      fill="#724CCA"
    />
  </svg>,
  'Subsidiary'
)
