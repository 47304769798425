import { Box, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { CloseIcon, EditOutlinedIcon } from '../../../../assets/icons'
import clsx from 'clsx'
import { Check } from '../../../../icons/Check'
import { LoadingButton } from '@mui/lab'
import { trpc } from '../../../../core/trpc'
import { toast } from 'react-hot-toast'
import { Stage_Columns } from '@prisma/client'
import { capitalize } from '../../../../utils'
import { AbilityContext } from '../../../../contexts/AbilityContext'
import { formatCurrency } from '../../../../utils/helpers'
import StyledCheckbox from '../../../../components/StyledCheckbox'
import { isNil } from 'ramda'
import { format } from 'date-fns'
import { LeadsByPipelineOutput } from '../../../../types/procedureOutputs'
import { leadTypeOptions } from '../../../../consts/lead'
import RefundReasonAutocomplete from '../../../../components/RefundReasonAutoComplete'

type DetailRowProps = {
  editRow: string
  setEditRow: React.Dispatch<React.SetStateAction<string>>
  lead: LeadsByPipelineOutput['results'][0] | undefined
  col: Stage_Columns
  isEditableField: boolean
  refetchLead: () => void
}

const DetailRow = ({ setEditRow, editRow, lead, col, refetchLead, isEditableField }: DetailRowProps) => {
  const ability = useContext(AbilityContext)
  const hasEditAbility = ability.can('edit', 'Lead')
  const isEditable = !editRow && hasEditAbility && isEditableField
  const isCurrencyField = (['PREAPPROVAL_AMOUNT', 'AVG_REVENUE', 'MERCHANT_REQUEST_AMOUNT', 'OFFER_AMOUNT'] as Stage_Columns[]).includes(col)
  const isBooleanField = (
    ['BM_WITH_APPROVAL', 'BM_WITH_STATEMENTS', 'PAYMENT_HISTORY', 'MERCHANT_REQUEST_AMOUNT_IS_MAX'] as Stage_Columns[]
  ).includes(col)
  const isNumberField = isCurrencyField || (['MERCHANT_CREDIT', 'NUM_OF_POSITIONS', 'TERM_AMOUNT'] as Stage_Columns[]).includes(col)
  const isDateField = [
    'DATE_LAST_INTERACTION',
    'BIRTHDAY',
    'LAST_CALL_LOG',
    'NEXT_TASK_DUE_DATE',
    'DATE_FIRST_INTERACTION',
    'NEWEST_EMAIL',
    'CREATED_AT',
    'DATE_FUNDED',
    'LAST_REASSIGNMENT_DATE',
    'BUSINESS_START_DATE'
  ].includes(col)
  const termLength = ['DAYS', 'WEEKS', 'MONTHS'] as const
  const val = lead?.[col.toLowerCase() as keyof LeadsByPipelineOutput['results'][0]] as string | number | boolean | undefined
  const [value, setValue] = useState(val)
  const [showEditIcon, setShowEditIcon] = useState(false)
  const mutation = trpc.lead.updateLead.useMutation({
    onSuccess: () => {
      refetchLead()
      setEditRow('')
    },
    onError: (e) => {
      toast.error(`Lead update failed: ${e.message}`)
    }
  })

  useEffect(() => {
    if (val) setValue(val)
  }, [val])

  const handleMouseEnter = () => {
    if (isEditable) {
      setShowEditIcon(true)
    }
  }

  const handleMouseLeave = () => {
    setShowEditIcon(false)
  }

  const handleRowClick = () => {
    if (isEditable) setEditRow(col)
  }

  if (!lead) return null

  const formattedColumn = col
    .split('_')
    .map((col) => capitalize(col))
    .join(' ')

  const input = () => {
    if (col === 'TERM_LENGTH')
      return (
        <TextField select value={value} onChange={(e) => setValue(e.target.value)} fullWidth>
          {termLength.map((type) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      )
    if (col === 'TYPE')
      return (
        <TextField select value={value} onChange={(e) => setValue(e.target.value)} fullWidth>
          {leadTypeOptions.map((type) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      )
    if (col === 'REFUND_REASON')
      return (
        <RefundReasonAutocomplete
          reason={value ? value.toString() : ''}
          onChange={(newValue) => {
            if (newValue) {
              setValue(newValue)
            } else {
              setValue('')
            }
          }}
        />
      )
    if (isBooleanField) return <StyledCheckbox checked={Boolean(value)} onChange={(e) => setValue(e.target.checked)} />
    return (
      <TextField
        sx={{
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none'
          }
        }}
        type={isNumberField ? 'number' : 'text'}
        value={col === 'DAYS_IN_STAGE' ? value || 0 : value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
      />
    )
  }

  const valDisplay = () => {
    if (isCurrencyField && value) return formatCurrency(value as string | number)
    if (col === 'HAS_FOLLOWUP') return lead.has_followup ? 'Yes' : 'No'
    if (col === 'FUNDER_NAME') return value !== undefined && value !== null ? String(value).toUpperCase() : ''
    if (isBooleanField && !isNil(value)) return value ? 'Yes' : 'No'
    if (isDateField && value) return format(new Date(value as string), 'MM/dd/yyyy')
    return value !== undefined && value !== null ? String(value) : ''
  }

  return (
    <Box>
      <Box
        onClick={handleRowClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`grid grid-cols-2 ${clsx({ 'cursor-pointer': isEditable, 'items-center': editRow !== col, 'items-start': editRow === col })}`}
      >
        <Typography className={isEditableField ? 'text-grey-800' : 'text-grey-500'}>{formattedColumn}</Typography>
        {editRow !== col && (
          <Box className="flex gap-4 item-center">
            <Typography data-cy={`value-${col}`} className="text-grey-700">
              {valDisplay()}
            </Typography>
            {showEditIcon && <EditOutlinedIcon className="text-grey-600" />}
          </Box>
        )}
        {editRow === col && (
          <Box>
            {input()}
            <Box className="mt-1 flex justify-start gap-2">
              <LoadingButton
                onClick={() => {
                  const updatedValue = value ?? (isNumberField ? 0 : isBooleanField ? false : '')
                  mutation.mutate({ id: lead?.id, [col.toLowerCase()]: updatedValue })
                }}
                loading={mutation.isLoading}
                className="p-2 border-grey-300 border-solid border-[1px] rounded-lg min-w-fit"
              >
                <Check className="text-grey-700 w-[18px] h-[18px]" />
              </LoadingButton>
              <IconButton
                onClick={() => {
                  setEditRow('')
                  setValue(val)
                }}
                className="p-2 border-grey-300 border-solid border-[1px] rounded-lg"
              >
                <CloseIcon className="text-grey-700 w-[18px] h-[18px]" />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default DetailRow
