import { Grid, Pagination } from '@mui/material'
import { trpc } from '../../core/trpc'
import { useEffect, useState } from 'react'
import { ContainerClient } from '@azure/storage-blob'
import { toast } from 'react-hot-toast'
import { toast as progressToast } from 'react-toastify'
import { FileOutput } from '../../types/procedureOutputs'
import { useConfirm } from 'material-ui-confirm'
import RenameFile from './RenameFile'
import AddDescription from './AddDescription'
import ReactDropzone from 'react-dropzone'
import FileItem from './FileItem'
import FileHeader from './FileHeader'
import FileViewer from './FileViewer'

type FilesProps = {
  id: string
  type: 'Lead' | 'Offer'
  onContractFileUpdateSuccess?: () => void
}

const Files = ({ id, type, onContractFileUpdateSuccess }: FilesProps) => {
  const confirm = useConfirm()
  const [skip, setSkip] = useState(0)
  const [previewFile, setPreviewFile] = useState<FileOutput | null>(null)
  const [fileNotFoundDialogOpen, setFileNotFoundDialogOpen] = useState(false)
  const [fileToRename, setFileToRename] = useState<FileOutput>()
  const [fileToDescribe, setFileToDescribe] = useState<FileOutput>()
  const [search, setSearch] = useState('')
  const [isContract, setContract] = useState(false)
  const TAKE = 10

  const { data, refetch, isLoading } =
    type === 'Lead'
      ? trpc.file.getFilesByLeadId.useQuery({ lead_id: id, skip, take: TAKE, search, onlyContract: isContract })
      : trpc.file.getFilesByOfferId.useQuery({ offer_id: id, skip, take: TAKE, search, onlyContract: isContract })
  const getLinks = trpc.file.createUploadFileLink.useMutation()

  // sync preview file on refetch: This is required b/c trpc does not push changes to FileViewer on refetch
  useEffect(() => {
    if (!previewFile) return

    const newPreviewFile = data?.results.find((file) => file.id === previewFile.id)
    if (newPreviewFile) {
      setPreviewFile(newPreviewFile)
    }
  }, [data?.results, previewFile])

  const onComplete = {
    onSuccess: () => {
      refetch()
      toast.success('Successfully added file')
    },
    onError: () => {
      toast.error('File upload failed')
    }
  }

  const deleteFileMutation = trpc.file.deleteFile.useMutation({
    onSuccess: () => {
      toast.success('Succesfully deleted file')
      refetch()
    },
    onError: () => toast.error('File deletion failed')
  })
  const createLeadFileMutation = trpc.file.createLeadFile.useMutation(onComplete)
  const createOfferFileMutation = trpc.file.createOfferFile.useMutation(onComplete)

  const updateFileMutation = trpc.file.updateFile.useMutation({
    onSuccess: (_, variables) => {
      refetch()
      toast.success(
        variables.name
          ? 'File name updated'
          : variables.description
          ? 'Description updated'
          : variables.is_contract !== undefined
          ? 'Contract file updated'
          : ''
      )
    }
  })

  const openFilePreview = (file: FileOutput) => {
    if (file.is_file_in_azure === false) {
      setFileNotFoundDialogOpen(true)
      return
    }

    setPreviewFile(file)
    return
  }

  const addFile = async (file: File) => {
    const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}
    if (containerUrl && sasQuery) {
      const arrayBufferFile = await file.arrayBuffer()
      const filePath = `${type}/${id}/${new Date().getTime()}-${file.name}`
      const container = new ContainerClient(containerUrl.concat('?').concat(sasQuery))

      const toastId = progressToast(`Uploading ${file.name} 0%`, { progress: 0, type: 'info', autoClose: false, closeButton: false })

      const onProgress = (progressEvent: { loadedBytes: number }) => {
        const percentCompleted = Math.round((progressEvent.loadedBytes / file.size) * 100)
        progressToast.update(toastId, { render: `Uploading ${file.name} ${percentCompleted}%`, progress: percentCompleted / 100 })
      }

      return container
        .uploadBlockBlob(filePath, arrayBufferFile, arrayBufferFile.byteLength, {
          onProgress,
          blobHTTPHeaders: {
            blobContentType: file.type
          }
        })
        .then(() => {
          progressToast.done(toastId)

          if (type === 'Lead') {
            createLeadFileMutation.mutate({
              lead_id: id,
              name: file.name,
              url: filePath,
              type: file.type,
              size: arrayBufferFile.byteLength
            })
          } else {
            createOfferFileMutation.mutate({
              offer_id: id,
              name: file.name,
              url: filePath,
              type: file.type,
              size: arrayBufferFile.byteLength
            })
          }
        })
        .catch((e) => toast.error(`Failed to create file. ${e}`))
    }
    throw Error('Failed to get secure link to upload file.')
  }

  const handleDelete = (file: FileOutput, callback?: () => void) => {
    confirm({ title: 'Delete File', description: `Are you sure you want to delete ${file.name}?` }).then(() => {
      deleteFileMutation.mutate({ id: file.id })
      callback?.()
    })
  }

  const handleDropAccepted = (files: File[]) => {
    files.forEach(async (file) => {
      addFile(file)
    })
  }

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
			if (event.target instanceof HTMLElement && (
				event.target.tagName === 'INPUT' ||
				event.target.isContentEditable
			)) {
				return;
			}

      if (!data || !previewFile || isLoading) return
      event.preventDefault()

      const isLeft = event.key === 'ArrowLeft'
      const isRight = event.key === 'ArrowRight'
			if (isLeft && hasPrevFile()) {
				await onPrevFile()
			}
      if (isRight && hasNextFile()) {
				await onNextFile()
			}
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [data, previewFile, isLoading])

	const currentIndex = data?.results.findIndex((file) => file.id === previewFile?.id)
	const hasPrevFile = () => {
		if (data === undefined || currentIndex === undefined) {
			return false
		}

		if (skip === 0 && currentIndex === 0) {
			return false
		}

		return true
	}

	const hasNextFile = () => {
		if (data === undefined || currentIndex === undefined) {
			return false
		}

		if (skip + currentIndex + 1 === data.count) {
			return false
		}

		return true
	}

	const onPrevFile = async () => {
		const prevFile = data?.results[Number(currentIndex) - 1]
		if (prevFile) {
			//prev file is within the current data set
			setPreviewFile(prevFile)
		} else {
			setSkip((skip) => skip - TAKE)
			const res = await refetch()
			if (res.data) setPreviewFile(res.data.results[TAKE - 1])
		}
	}

	const onNextFile = async () => {
		const nextFile = data?.results[Number(currentIndex) + 1]
		if (nextFile) {
			//next file is within current data set
			setPreviewFile(nextFile)
		} else {
			setSkip((skip) => skip + TAKE)
			const res = await refetch()
			if (res.data) setPreviewFile(res.data.results[0])
		}
	}

  return (
    <ReactDropzone
      multiple
      maxSize={Infinity}
      noClick
      onDropAccepted={handleDropAccepted}
      onDropRejected={(rejections) => {
        const rejectionMessages = rejections[0].errors.map((err) => err.message).join('\n')
        toast.error(rejectionMessages)
      }}
    >
      {({ getRootProps, open }) => (
        <Grid {...getRootProps()} container className="flex flex-col gap-6">
          <FileHeader
            fileCount={data?.count}
            setSearch={setSearch}
            open={open}
            isGetLinksLoading={getLinks.isLoading}
            type={type}
            addFile={addFile}
            isCreateLeadFileMutationLoading={createLeadFileMutation.isLoading}
            isCreateOfferFileMutationLoading={createOfferFileMutation.isLoading}
            id={id}
            refetch={refetch}
            isContract={isContract}
            setContract={setContract}
          />
          <Grid container item>
            {data?.results.map((f: FileOutput) => (
              <FileItem
                key={f.id}
                file={f}
                fileNotFoundDialogOpen={fileNotFoundDialogOpen}
                setFileNotFoundDialogOpen={setFileNotFoundDialogOpen}
                handleDelete={handleDelete}
                setFileToRename={setFileToRename}
                setFileToDescribe={setFileToDescribe}
                onPreviewFile={() => openFilePreview(f)}
                updateFileContract={async ({ is_contract, id }) => {
                  await updateFileMutation.mutateAsync({ id, is_contract })
                  onContractFileUpdateSuccess?.()
                }}
                updateIsLoading={updateFileMutation.isLoading}
              />
            ))}
          </Grid>
          <Grid item display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil((data?.count || 0) / TAKE)}
              boundaryCount={2}
              variant="outlined"
              shape="rounded"
              onChange={(_e, page) => setSkip((page - 1) * TAKE)}
              page={Math.ceil(skip / TAKE) + 1}
            />
          </Grid>
          {Boolean(fileToRename) && <RenameFile refetch={refetch} file={fileToRename!} setFile={setFileToRename} />}
          {Boolean(fileToDescribe) && <AddDescription refetch={refetch} file={fileToDescribe!} setFile={setFileToDescribe} />}

          <FileViewer
            file={previewFile ?? undefined}
            refetch={() => refetch()}
            open={previewFile != null}
            onClose={() => {
              setPreviewFile(null)
            }}
            hasPrevFile={hasPrevFile()}
            onPrevFile={onPrevFile}
            hasNextFile={hasNextFile()}
            onNextFile={onNextFile}
          />
        </Grid>
      )}
    </ReactDropzone>
  )
}

export default Files
