import React from 'react'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { CheckIcon, CloseIcon, EditOutlinedIcon } from '../../../assets/icons'

export interface EditFieldProps {
  label: string
  value: string
  placeholder?: string
  disabled?: boolean

  onEditMode?: () => void
  isEditMode?: boolean

  // the value being edited
  editValue?: string
  onEditValueChange?: (val: string) => void

  onCancelEdit?: () => void
  onSaveEdit?: () => void
}

export function EditField({
  label,
  value,
  placeholder,
  disabled,
  onEditMode,
  isEditMode,
  editValue,
  onEditValueChange,
  onCancelEdit,
  onSaveEdit
}: EditFieldProps) {
  return (
    <Box className="flex flex-row gap-4 items-baseline">
      <Typography className="text-grey-700 text_sm_medium mt-2 mb-1">{label}</Typography>

      {isEditMode ? (
        <TextField
          fullWidth
          onChange={(e) => onEditValueChange!(e.target.value)}
          autoFocus
          value={editValue}
          placeholder={placeholder}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <Box className="flex gap-1">
                <IconButton className="rounded-lg border-solid border-[1px] border-grey-200 p-1 h-fit" onClick={onSaveEdit}>
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton className="rounded-lg border-solid border-[1px] border-grey-200 p-1 h-fit" onClick={onCancelEdit}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            )
          }}
        />
      ) : (
        <Box className="flex gap-2 item-center">
          <Typography className="text-grey-700 truncate max-w-[250px]">{value}</Typography>

          {!disabled && (
            <IconButton onClick={onEditMode}>
              <EditOutlinedIcon className="text-grey-600 w-[14px] h-[14px]" />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  )
}
