import { Box, Typography } from '@mui/material'
import { OfferPendingOffer } from '../../../types/procedureOutputs'
import { ExternalLink } from 'react-feather'
import { useNavigate } from 'react-router'

const PendingOffer = ({ offer }: { offer: OfferPendingOffer }) => {
  const navigate = useNavigate()

  return (
    <Box className="px-3 py-2 rounded-lg border-grey-200 border-solid justify-between flex">
      <Box>
        <Typography className="text-grey-900 text_lg_semibold pb-2">{`${offer.lead.company.name} - ${offer.lead.name} - ${offer.funder.name}`}</Typography>
        <Typography className="text-grey-900 text_md_normal">{offer.pending_broker_reason}</Typography>
      </Box>
      <Box className="items-center flex cursor-pointer" onClick={() => navigate(`/pipeline/${offer.lead.stage.pipeline_id}/lead/${offer.lead_id}`)}>
        <ExternalLink className="text-primary-500 w-6 h-6 pr-2" />
        <Typography className="text-primary-500 text_sm_medium">View Deal</Typography>
      </Box>
    </Box>
  )
}

export default PendingOffer
