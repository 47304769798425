import { Box, IconButton, Typography } from '@mui/material'
import { CloseIcon, ErrorOutlineOutlinedIcon } from '../../../assets/icons'

type ReassignmentRiskProps = {
  daysUntilRiskOfReassignment: number
  onClose: () => void
}

const ReassignmentCard = ({ daysUntilRiskOfReassignment, onClose }: ReassignmentRiskProps) => {
  const message = () => {
    if (daysUntilRiskOfReassignment <= 0) return ' This lead is at risk of reassignment'
    return ` This lead will be reassigned in ${daysUntilRiskOfReassignment} days`
  }

  return (
    <Box className="border-[1px] rounded-xl border-solid border-error-200 px-5 py-[14px]">
      <Box className="flex justify-between items-center">
        <Box className="flex items-start gap-2">
          <ErrorOutlineOutlinedIcon className="text-error-700" />
          <Box>
            <Typography className="text-error-800 text_md_medium">Risk of Re-assignment</Typography>
            <Typography className="text_sm_normal text-error-800 mt-2">
              <Typography className="text-error-800 text_sm_medium" component="span">
                Inactivity:
              </Typography>
              {message()}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={() => onClose()} className="p-0.5">
          <CloseIcon className="text-error-500" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ReassignmentCard
