import React from 'react'
import { Menu, MenuItem, Typography } from '@mui/material'
import { FileOutput } from '../../../types/procedureOutputs'
import { Download } from '../../../icons/Download'
import { DeleteOutlinedIcon } from '../../../assets/icons'
import CheckCircle from '../../../icons/CheckCircle'

export interface FileViewerMenuProps {
  file: FileOutput

  anchorElement: HTMLElement | null
  onClose: () => void

  onAction: (action: FileViewerMenuActions) => void
}

export enum FileViewerMenuActions {
  Download,
  MarkAsContract,
  Delete
}

export const FileViewerMenu = ({ file, anchorElement, onClose, onAction }: FileViewerMenuProps) => {
  const menuItems = [
    {
      id: FileViewerMenuActions.Download,
      title: 'Download',
      Icon: Download
    },
    {
      id: FileViewerMenuActions.MarkAsContract,
      title: `${file?.is_contract ? 'Unmark' : 'Mark'} as contract file`,
      Icon: CheckCircle
    },
    {
      id: FileViewerMenuActions.Delete,
      title: 'Delete',
      Icon: DeleteOutlinedIcon
    }
  ]

  return (
    <Menu open={Boolean(anchorElement)} onClose={onClose} anchorEl={anchorElement}>
      {menuItems.map(({ id, title, Icon }) => (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            onAction(id)
            onClose()
          }}
          key={id}
        >
          <Icon fontSize="small" />
          <Typography className="text-grey-700 ml-1">{title}</Typography>
        </MenuItem>
      ))}
    </Menu>
  )
}
