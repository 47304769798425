import { Button, Chip, TableCell, TableRow, Typography } from '@mui/material'
import { GetBrokersBreakdownByDealOutput } from '../../../../types/procedureOutputs'
import { formatCurrency } from '../../../../utils/helpers'
import { Can } from '../../../../contexts/AbilityContext'

const BrokerCommissionRow = ({
  row,
  handlePay,
  showPayUserButton = true
}: {
  row: GetBrokersBreakdownByDealOutput[number]
  handlePay: () => void
  showPayUserButton?: boolean
}) => {
  return (
    <TableRow>
      <TableCell sx={{ paddingY: '8px' }}>
        <Typography data-cy="brokerName" className="text_md_medium">
          {row.user_name}
        </Typography>
      </TableCell>
      <TableCell sx={{ paddingY: '8px' }}>
        <Typography data-cy="percentage" className="text-grey-700">
          {row.percentage}%
        </Typography>
      </TableCell>
      <TableCell sx={{ paddingY: '8px' }}>
        <Typography data-cy="commission" className="text-grey-700">
          {formatCurrency(row.total_amount)}
        </Typography>
      </TableCell>
      <TableCell sx={{ paddingY: '8px' }}>
        <Chip data-cy="amountReceived" size="small" className="!bg-success-50 !text-success-900" label={formatCurrency(row.amount_received)} />
      </TableCell>
      <TableCell sx={{ paddingY: '8px' }}>
        <Chip data-cy="amountPending" size="small" className="!bg-error-50 !text-error-900" label={formatCurrency(row.amount_pending)} />
      </TableCell>
      {showPayUserButton && (
        <Can I="full" a="Invoice">
          <TableCell align="right" sx={{ paddingY: '8px' }}>
            <Button className="text_xs_medium" onClick={handlePay} size="small" variant="outlined">
              Pay Broker
            </Button>
          </TableCell>
        </Can>
      )}
    </TableRow>
  )
}

export default BrokerCommissionRow
