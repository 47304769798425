import { Box } from '@mui/system'
import MaterialReactTable, { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import React, { useMemo, useState } from 'react'
import { ValidationFilter } from '.'
import { InformationForValidationOutput, StagesForValidationOutput } from '../../../types/procedureOutputs'
// import { useNavigate } from 'react-router'
import { Chip, Typography } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import EditOutlined from '@mui/icons-material/EditOutlined'
import { useConfirm } from 'material-ui-confirm'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import EditRuleModal from './EditRuleModal'
import { Can } from '../../../contexts/AbilityContext'

type RuleListProps = {
  data: StagesForValidationOutput | undefined
  setFilters: React.Dispatch<React.SetStateAction<ValidationFilter>>
  filters: ValidationFilter
  refetch: () => void
  information: InformationForValidationOutput | undefined
}

const RuleList = ({ filters, setFilters, data, refetch, information }: RuleListProps) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [editOpen, setEditOpen] = useState<string | null>(null)
  const confirm = useConfirm()
  //   const navigate = useNavigate()
  const deleteRequiredFieldMutation = trpc.stage.deleteRequiredFieldMutation.useMutation({
    onSuccess: () => {
      toast.success('Required fields deleted.')
      refetch()
    },
    onError: () => toast.error('Failed to delete required fields')
  })

  const tableCellData = useMemo<MRT_ColumnDef<StagesForValidationOutput[0]>[]>(() => {
    return [
      {
        header: 'Stage',
        id: 'stage',
        enableSorting: true,
        size: 70,
        accessorFn: (row: StagesForValidationOutput[0]) => (
          <Box className="flex">
            <Box sx={{ backgroundColor: `${row.color}` }} className="rounded w-6" />
            <Typography className="text-grey-900 text_md_medium ml-2">{row.name}</Typography>
          </Box>
        )
      },
      {
        header: 'Pipeline',
        id: 'pipeline',
        enableSorting: true,
        size: 70,
        accessorFn: (row: StagesForValidationOutput[0]) => <Typography className="text-grey-700 text_md_normal">{row.pipeline.name}</Typography>
      },
      {
        header: 'Required Fields',
        id: 'required_fields',
        size: 300,
        accessorFn: (row: StagesForValidationOutput[0]) => (
          <Box className="flex justify-between w-[90%]">
            <Box className="flex overflow-scroll">
              {row.required_fields.map((r) => (
                <Chip
                  key={r + row.id}
                  label={<Typography className="text_sm_normal text-[#1700C0] mr-2">{r.replaceAll('_', ' ')}</Typography>}
                  className="bg-secondary-50 mr-2"
                  size="small"
                />
              ))}
            </Box>
            <Can I="edit" a="Pipeline">
              <Box className="flex gap-8">
                <DeleteOutline
                  onClick={() =>
                    confirm({
                      title: 'Delete Validation Rule',
                      description: `Are you sure you want to delete this rule?`
                    })
                      .then(() => {
                        deleteRequiredFieldMutation.mutate({ id: row.id })
                      })
                      .finally(() => {
                        refetch()
                      })
                  }
                />
                <EditOutlined onClick={() => setEditOpen(row.id)} />
              </Box>
            </Can>
          </Box>
        )
      }
    ]
  }, [data])
  return (
    <Box className="overflow-auto">
      <Box className="overflow-auto bg-white">
        <MaterialReactTable
          initialState={{ pagination: { pageSize: filters.take, pageIndex: 1 } }}
          data={data || []}
          columns={tableCellData.flat()}
          enableColumnDragging={false}
          enableColumnOrdering={false}
          enableColumnFilters={false}
          enableDensityToggle={false}
          enableSortingRemoval={false}
          enableSelectAll={true}
          enableFilters={false}
          enableColumnActions={false}
          enableRowVirtualization
          manualSorting
          enableTopToolbar={false}
          enableSorting
          enableMultiSort
          muiTableBodyRowProps={({ row }) => ({
            // onClick: () => navigate(`/settings/users/${row.original.id}`),
            sx: { cursor: 'pointer', background: 'white', padding: 0 }
          })}
          muiTablePaginationProps={{
            page: Math.floor(filters.skip / filters.take),
            onPageChange: (event, page) => {
              setFilters((filters) => ({ ...filters, skip: page * filters.take }))
            },
            showFirstButton: false,
            showLastButton: false,
            rowsPerPageOptions: [filters.take]
          }}
          manualPagination={true}
          //   rowCount={data?.count}
          enableTableFooter
          state={{ sorting }}
          onSortingChange={setSorting}
        />
      </Box>
      {editOpen && (
        <EditRuleModal
          closeModal={() => {
            setEditOpen(null)
            refetch()
          }}
          stageData={data?.find((d) => d.id === editOpen) ?? undefined}
          fieldNames={information?.requiredFields ?? []}
          stages={information?.stageNames || []}
          currentStageId={editOpen ?? ''}
        />
      )}
    </Box>
  )
}
export default RuleList
