import { trpc } from '../../../core/trpc'
import { Box, CircularProgress, Divider, Drawer, Tooltip, Typography } from '@mui/material'
import { OfferGetOfferById } from '../../../types/procedureOutputs'
import Info from './Info'
import Header from './Header'
import EditableField from './EditableField'
import OfferActions from '../OfferActions'
import Terms from './Terms'
import Files from '../../../components/files/files'
import { Can } from '../../../contexts/AbilityContext'
import FunderContacts from '../../funders/funderDetails/FunderContacts'
import OfferAuditLogList from './AuditLogList'
import { InformationCircleOutlined } from '../../../icons/information-circle-outlined'
import BrokersCommissionTable from '../../accounting/commissions/components/BrokersCommissionTable'
import PendingOfferSideDrawer from '../pendingOffers/PendingOfferSidedrawer'

type OfferDetailsProps = {
  refetch: () => void
  offerId: string
  handleClose: () => void
}

export type Offer = OfferGetOfferById

const OfferDetails = ({ refetch, offerId, handleClose }: OfferDetailsProps) => {
  const {
    data: offer,
    refetch: refetchOffer,
    isLoading,
    error
  } = trpc.offer.getOfferById.useQuery({ id: offerId || '' }, { enabled: Boolean(offerId) })

  const refresh = () => {
    refetch()
    refetchOffer()
  }

  if (!offerId) return null
  return (
    <Drawer anchor="right" open={Boolean(offer)} onClose={() => handleClose()}>
      <Box className="p-6 w-[624px] flex flex-col justify-between h-full mobile:w-[90vw] max-h-screen">
        {isLoading ? (
          <CircularProgress />
        ) : offer ? (
          <Box className="flex-grow flex flex-col gap-6">
            <Header handleClose={handleClose} offer={offer} refetch={refetch} />
            {offer?.pending_broker && <PendingOfferSideDrawer offer={offer as Offer} refetch={refetchOffer} />}
            <Info offer={offer} />
            <Divider />
            <Box>
              <FunderContacts refetch={refresh} titleText="Funder Contacts" funder={{ ...offer.funder, _count: { offers: 0 } }} />
            </Box>
            <Divider />
            <Can I="edit" a="Offer">
              <Box>
                <Typography className="text-grey-700 text_lg_semibold mb-6">Edit Status</Typography>
                <OfferActions offer={offer} refetch={refresh} />
              </Box>
              <Divider />
            </Can>
            <Box className="grid grid-cols-2 gap-4">
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Amount Offered"
                value={offer?.amount_offered || ''}
                field="amount_offered"
                refetch={refresh}
              />
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Final Purchase Price"
                value={offer?.final_purchase_price || ''}
                field="final_purchase_price"
                refetch={refresh}
              />
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Date Funded"
                value={offer?.date_funded || ''}
                field="date_funded"
                refetch={refresh}
              />
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Period Payment Amount"
                tooltip={
                  <Tooltip title="How much you pay per period">
                    <InformationCircleOutlined fontSize="small" className="ml-2" />
                  </Tooltip>
                }
                value={offer?.period_payment_amount || ''}
                field="period_payment_amount"
                refetch={refresh}
              />
            </Box>
            <Divider />
            <Box className="grid grid-cols-2 gap-4">
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Commission Points"
                value={offer?.commission_points || ''}
                field="commission_points"
                refetch={refresh}
              />
              <EditableField leadId={offer.lead_id} id={offerId} title="Buy Rate" value={offer?.buy_rate || ''} field="buy_rate" refetch={refresh} />
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Service Fee"
                value={offer?.service_fee || ''}
                field="service_fee"
                refetch={refresh}
              />
              <Terms
                offer={offer}
                term_amount={offer.term_amount?.toString()}
                term_length={offer.term_length}
                term_frequency={offer.term_frequency}
                refetch={refresh}
              />
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Purchase Amount"
                value={offer?.purchase_amount || ''}
                field="purchase_amount"
                refetch={refresh}
              />
              <EditableField
                leadId={offer.lead_id}
                id={offerId}
                title="Final Purchase Amount"
                value={offer?.final_purchase_amount || ''}
                field="final_purchase_amount"
                refetch={refresh}
              />
            </Box>
            <Divider />
            {offer?.reason_for_decline && (
              <>
                <EditableField
                  leadId={offer.lead_id}
                  id={offerId}
                  title={'Reason for Decline'}
                  value={offer?.reason_for_decline}
                  field={'reason_for_decline'}
                  refetch={refresh}
                />
                <Divider />
              </>
            )}
            {Boolean(offer.invoices?.length) && (
              <Can I="view" a="InvoiceCommission">
                <>
                  <BrokersCommissionTable offerId={offer.id} showPayUserButton={false} />
                  <Divider />
                </>
              </Can>
            )}
            <EditableField leadId={offer.lead_id} id={offerId} title="Notes" value={offer?.notes || ''} field="notes" refetch={refresh} />
            <Divider />
            <EditableField
              leadId={offer.lead_id}
              id={offerId}
              title="Funder Notes"
              value={offer?.funder_notes || ''}
              field="funder_notes"
              refetch={refresh}
            />
            <Divider />
            <Files type="Offer" id={offerId} />
            <Divider />
            <OfferAuditLogList offer_id={offerId} />
          </Box>
        ) : (
          <Typography>There was an error loading the offer. {error?.message}</Typography>
        )}
      </Box>
    </Drawer>
  )
}

export default OfferDetails
