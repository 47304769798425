import { Autocomplete, Box, Button, Dialog, Divider, FormControlLabel, IconButton, TextField, Typography } from '@mui/material'
import {
  AccessTimeOutlinedIcon,
  AddCircleOutlineOutlinedIcon,
  CalendarTodayOutlinedIcon,
  CheckIcon,
  CloseIcon,
  DeleteOutlinedIcon
} from '../../assets/icons'
import { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DatePicker, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import { update } from 'ramda'
import { addHours, format, isWithinInterval, set, startOfDay, startOfTomorrow } from 'date-fns'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { LeadsByUser } from '../../types/procedureOutputs'
import debounce from 'lodash.debounce'
import { trpc } from '../../core/trpc'
import { useQueryClient } from '@tanstack/react-query'
import StyledCheckbox from '../StyledCheckbox'

type Lead = LeadsByUser['results'][number]

type AddTaskProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  refetch?: () => void
}

type Task = { id: string; title: string; date: Date | null; time: Date | null; reminder_email: boolean | null }

const AddTask = ({ open, setOpen, refetch }: AddTaskProps) => {
  const { leadId } = useParams()
  const client = useQueryClient()
  const [tasks, setTasks] = useState<Task[]>([{ id: uuidv4(), title: '', date: null, time: startOfDay(new Date()), reminder_email: false }])
  const [search, setSearch] = useState('')
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null)
  const [editMode, setEditMode] = useState<string | undefined>(tasks[0].id)
  const { data: userLeads } = trpc.lead.getLeadsBySearch.useQuery({ search, taskAssignedTo: true }, { enabled: !leadId })

  const mutation = trpc.task.createTasks.useMutation({
    onSuccess: () => {
      toast.success('Tasks successfully created')
      if (refetch) {
        refetch()
      } else if (leadId) {
        // AddTask is used from within a specific lead
        client.refetchQueries({ queryKey: [['task', 'getTasksByLead'], { input: { leadId }, type: 'query' }] })
      } else {
        // AddTask is used from Tasks page
        client.refetchQueries({
          queryKey: [
            ['task', 'getTasks'],
            {
              input: { orderBy: {}, search: '', showSubordinateTasks: false, startDate: startOfTomorrow() },
              type: 'query'
            }
          ]
        })
      }
      setOpen(false)
    },
    onError: (e) => {
      toast.error(e.message)
    }
  })

  const handleSave = () => {
    const formattedTasks = tasks.map((task) => {
      const { date, time, reminder_email } = task
      const due_date =
        date && time
          ? set(date, {
              hours: time.getHours(),
              minutes: time.getMinutes(),
              seconds: time.getSeconds()
            })
          : new Date()

      return { title: task.title, due_date, reminder_email: reminder_email ?? false }
    })
    mutation.mutate({ lead_id: leadId || selectedLead?.id || '', tasks: formattedTasks })
  }

  const handleAdd = () => {
    const id = uuidv4()
    setTasks((tasks) => [...tasks, { id, title: '', date: null, time: startOfDay(new Date()), reminder_email: false }])
    setEditMode(id)
  }

  const handleInputChange = (value: string) => {
    debouncedSearch(value)
  }

  const debouncedSearch = useMemo(() => debounce((search: string) => setSearch(search), 500), [])

  const disabled = useMemo(() => {
    const missingTitle = tasks.some((task) => !task.title)
    return (!leadId && !selectedLead) || missingTitle
  }, [leadId, selectedLead, tasks])

  return (
    <Dialog open={open}>
      <Box className="p-6" sx={{ width: { xs: '100%', md: '400px' } }}>
        <Box className="flex justify-between items-center mb-5">
          <Typography className="text-grey-900 text_xl_medium">Add Task</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        {!leadId && (
          <>
            <Typography className="text-grey-700 text_sm_normal mb-1.5">Select Lead</Typography>
            <Autocomplete
              fullWidth
              onInputChange={(_, v) => handleInputChange(v)}
              value={selectedLead}
              getOptionLabel={(option) => `${option.company.name} - ${option.name}`}
              options={userLeads?.results || []}
              onChange={(_, v) => setSelectedLead(v)}
              renderInput={(params) => <TextField {...params} />}
            />
            <Divider className="my-6" />
          </>
        )}
        <Box className="flex flex-col gap-5">
          {tasks.map((task) => (
            <Task key={task.id} setEditMode={setEditMode} task={task} setTasks={setTasks} editMode={editMode === task.id} />
          ))}
          <Button disabled={Boolean(editMode)} onClick={handleAdd} className="text-primary-500 px-0 w-fit">
            <AddCircleOutlineOutlinedIcon fontSize="small" />
            <Typography className="text_sm_medium ml-2">Add Task</Typography>
          </Button>
        </Box>
        <Divider className="mt-8 mb-6" />
        <Box className="grid grid-cols-2 gap-3 float-right">
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton disabled={disabled} loading={mutation.isLoading} onClick={handleSave} variant="contained">
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddTask

type TaskProps = {
  task: Task
  editMode: boolean
  setEditMode: React.Dispatch<React.SetStateAction<string | undefined>>
  setTasks: React.Dispatch<React.SetStateAction<Task[]>>
}

const Task = ({ task, editMode, setTasks, setEditMode }: TaskProps) => {
  const [title, setTitle] = useState(task.title)
  const [date, setDate] = useState(task.date)
  const [time, setTime] = useState(task.time)
  const [reminder, setReminder] = useState(task.reminder_email)

  const handleSave = () => {
    if (title) {
      setTasks((tasks) => {
        const index = tasks.findIndex((t) => t.id === task.id)
        return update(index, { ...task, title, date, time, reminder_email: reminder }, tasks)
      })
      setEditMode(undefined)
    }
  }

  const handleDelete = () => {
    setTasks((tasks) => {
      if (tasks.length === 1) {
        return [{ id: uuidv4(), title: '', date: null, time: null, reminder_email: false }]
      }
      setEditMode(undefined)
      return tasks.filter((t) => t.id !== task.id)
    })
  }

  if (editMode) {
    return (
      <Box>
        <TextField
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
          autoFocus
          value={title}
          placeholder="Enter task..."
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <Box className="flex gap-1">
                <IconButton className="rounded-lg border-solid border-[1px] border-grey-200 p-1 h-fit" onClick={handleSave}>
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton className="rounded-lg border-solid border-[1px] border-grey-200 p-1 h-fit" onClick={handleDelete}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            )
          }}
        />
        <Typography className="text-grey-700 text_sm_normal mt-2 mb-1">Due Date</Typography>
        <Box className="grid grid-cols-2 gap-5">
          <DatePicker disablePast value={date} onChange={(value) => setDate(value)} />
          <TimePicker
            value={time}
            onChange={(value) => setTime(value)}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock
            }}
          />
        </Box>
        <FormControlLabel
          onChange={(_, checked) => setReminder(checked)}
          label="Email Reminder"
          disabled={isWithinInterval(time || new Date(), { start: new Date(), end: addHours(new Date(), 1) })}
          control={<StyledCheckbox data-cy="reminderCheckbox" checked={reminder ?? false} />}
        />
      </Box>
    )
  }

  return (
    <Box className="cursor-pointer" onClick={() => setEditMode(task.id)}>
      <Box className="flex justify-between items-center">
        <Typography className="text-grey-700 mb-2">{task.title}</Typography>
        <IconButton onClick={handleDelete}>
          <DeleteOutlinedIcon fontSize="small" className="text-grey-500" />
        </IconButton>
      </Box>
      <Box className="text-grey-500 flex gap-5">
        <Box className="flex items-center gap-2">
          <CalendarTodayOutlinedIcon />
          <Typography>{format(task.date || new Date(), 'MMM dd')}</Typography>
        </Box>
        <Box className="flex items-center gap-2">
          <AccessTimeOutlinedIcon />
          <Typography>{format(task.time || new Date(), 'p')}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
