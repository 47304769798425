import { Box, Button, CircularProgress, Collapse, Divider, Typography } from '@mui/material'
import Header from './Header'
import ActivityRow from './ActivityRow'
import { useEffect, useMemo, useState } from 'react'
import { trpc } from '../../../../core/trpc'
import Files from '../../../../components/files/files'
import LogRow from './LogRow'
import {
  ActivityGetActivityByLeadId,
  ActivityGetActivityByLeadIdResults,
  AuditTrailLeadLog,
  LeadAssignmentHistory,
  GetMessagesOutput
} from '../../../../types/procedureOutputs'
import { groupBy } from 'ramda'
import { format } from 'date-fns'
import AssignmentRow from './AssignmentRow'
import { useSession } from 'next-auth/react'
import Search from '../../../../../src/components/Search'
import AddNote from '../AddNote'
import clsx from 'clsx'
import { Virtuoso } from 'react-virtuoso'
import NewEmailDrawer from '../../../../components/email/NewEmailDrawer'
import Messages from './Messages'

export type ActivityFilter = {
  skip: number
  take: number
  activityType: string | undefined
}

export type FlatAssignmentHistory = {
  type: 'assigned' | 'unassigned'
  date: Date
  by?: string | null
  assignee: string | null
}

type FlatAssignmentHistoryArray = FlatAssignmentHistory[]

type LeadActivityProps = {
  leadId: string
  collapsible?: boolean
  isNewEmailOpen?:
    | {
        isOpen: boolean
        requestInfo?: boolean | undefined
      }
    | undefined
  setNewEmailOpen?: React.Dispatch<
    React.SetStateAction<
      | {
          isOpen: boolean
          requestInfo?: boolean | undefined
        }
      | undefined
    >
  >
  defaultDisplayType?: 'activity' | 'file' | 'edit' | 'assignment' | 'message'
  refetchRequiredColValues?: () => void
}

const LeadActivity = ({
  leadId,
  collapsible,
  isNewEmailOpen = undefined,
  setNewEmailOpen,
  defaultDisplayType,
  refetchRequiredColValues
}: LeadActivityProps) => {
  const session = useSession()
  const [expanded, setExpanded] = useState(true)

  const initFilters = {
    skip: 0,
    take: 10,
    activityType: undefined
  }
  const [filters, setFilters] = useState<ActivityFilter>(initFilters)
  const [search, setSearch] = useState('')
  const [addNoteOpen, setAddNoteOpen] = useState(false)
  const [displayType, setDisplayType] = useState<'activity' | 'file' | 'edit' | 'assignment' | 'message'>(defaultDisplayType || 'activity')
  const [activities, setActivities] = useState<ActivityGetActivityByLeadId>({ count: 0, results: [] })
  const [messages, setMessages] = useState<GetMessagesOutput>({ count: 0, results: [], cursor: undefined })
  const [assignmentHistory, setAssignmentHistory] = useState<LeadAssignmentHistory>({ results: [] })
  const [leadLogs, setLeadLogs] = useState<{ results: AuditTrailLeadLog[]; count: number }>({ count: 0, results: [] })
  const [loading, setLoading] = useState(false)
  const { data: lead } = trpc.lead.getLeadById.useQuery({ id: leadId })
  const trpcContext = trpc.useContext()

  const fetchActivities = async ({ concat, activityType }: { concat: boolean; activityType: string | undefined }) => {
    setLoading(true)
    const response = await trpcContext.activity.getActivitiesByLeadId.fetch({
      ...filters,
      activityType: activityType,
      leadId: leadId || '',
      email_subject_search: search,
      skip: concat ? filters.skip + filters.take : 0
    })
    setLoading(false)
    const newData = concat ? [...activities.results, ...response.results] : response.results
    setActivities({ count: response.count, results: newData })
    if (concat) setFilters({ ...filters, activityType, skip: filters.skip + filters.take })
    else setFilters({ activityType, skip: 0, take: filters.take })
  }

  const fetchAssignments = async ({ concat }: { concat: boolean }) => {
    setLoading(true)
    const response = await trpcContext.userLeads.getAssignmentHistory.fetch({ leadId })
    setLoading(false)
    if (concat) setFilters({ ...filters, skip: filters.skip + filters.take })
    else setFilters({ activityType: undefined, skip: 0, take: filters.take })
    const newData = concat ? [...assignmentHistory.results, ...response.results] : response.results
    setAssignmentHistory({ results: newData })
  }

  const fetchMessages = async ({ concat }: { concat: boolean }) => {
    setLoading(true)
    const response = await trpcContext.message.getMessages.fetch({ leadId, cursor: concat ? messages.cursor : undefined })
    setLoading(false)
    const allResults = concat ? [...messages.results, ...response.results] : response.results
    setMessages({ count: response.count, results: allResults, cursor: response.cursor })
  }

  const fetchLeadLogs = async ({ concat }: { concat: boolean }) => {
    setLoading(true)
    const response = await trpcContext.auditTrail.getLeadLogs.fetch({ lead_id: leadId, ...filters })
    setLoading(false)
    if (concat) setFilters({ ...filters, skip: filters.skip + filters.take })
    else setFilters({ activityType: undefined, skip: 0, take: filters.take })
    const newData = concat ? [...leadLogs.results, ...response.results] : response.results
    setLeadLogs({ count: response.count, results: newData })
  }

  useEffect(() => {
    if (displayType === 'activity') {
      fetchActivities({ concat: false, activityType: filters.activityType })
    } else if (displayType === 'assignment') {
      fetchAssignments({ concat: false })
    } else if (displayType === 'edit') {
      fetchLeadLogs({ concat: false })
    } else if (displayType === 'message') {
      fetchMessages({ concat: false })
    }
    return () => {
      // reset state if leadId or displaytype changes
      if (displayType === 'activity') {
        setActivities({ count: 0, results: [] })
      }
    }
  }, [displayType, leadId])

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
    if (Math.abs(scrollHeight - scrollTop - clientHeight) < 1) {
      if (displayType === 'activity' && activities.results.length < activities.count) {
        fetchActivities({ concat: true, activityType: filters.activityType })
      } else if (displayType === 'message' && messages.results.length < messages.count) {
        fetchMessages({ concat: true })
      } else if (leadLogs.results.length < leadLogs.count) {
        fetchLeadLogs({ concat: true })
      }
    }
  }

  const count =
    displayType === 'activity'
      ? activities.count
      : displayType === 'assignment'
      ? assignmentHistory.results.length
      : displayType === 'message'
      ? messages.count
      : leadLogs.count
  const activityItems =
    Object.values(
      groupBy(
        (r) => (r?.email?.from_address ? `${r?.email?.from_address}-${format(new Date(r.date_of_activity), 'yyyy-MM-dd')}` : r?.id),
        activities.results
      )
    ) ?? []

  const mappedHistory = useMemo(() => {
    const history: FlatAssignmentHistoryArray = assignmentHistory.results.flatMap((r) => {
      if (r.unassigned_at) {
        return [
          { type: 'unassigned', date: new Date(r.unassigned_at), by: r.unassigned_by?.name, assignee: r.assigned_to.name },
          { type: 'assigned', date: new Date(r.assigned_at), by: r.assigned_by?.name, assignee: r.assigned_to.name }
        ]
      }
      return { type: 'assigned', date: new Date(r.assigned_at), by: r.assigned_by?.name, assignee: r.assigned_to.name }
    })

    return history.sort((a, b) => b.date.getTime() - a.date.getTime())
  }, [assignmentHistory.results])

  return (
    <Box
      className={`flex flex-col max-h-[450px] overflow-x-auto relative ${clsx({ 'gap-6': expanded, 'mt-4': collapsible })}`}
      sx={{ backgroundColor: 'white', borderRadius: '12px', border: '1px solid #E3E8EF', padding: '20px' }}
    >
      <Header
        messageCount={messages.count}
        expanded={expanded}
        setExpanded={setExpanded}
        filters={filters}
        setFilters={setFilters}
        refetch={fetchActivities}
        displayType={displayType}
        setDisplayType={setDisplayType}
        collapsible={collapsible}
      />
      <Collapse in={!collapsible || expanded}>
        <Divider className="my-3" />
        {filters.activityType === 'EMAIL' && (
          <Search
            isDebounced
            onSearch={(s) => {
              setSearch(s)
            }}
          />
        )}
        {displayType !== 'file' && (
          <Box>
            {filters.activityType === 'NOTE' && (
              <Button onClick={() => setAddNoteOpen(true)} variant="contained" className="float-right">
                Add Note
              </Button>
            )}
            {displayType === 'activity' && (
              <Virtuoso
                onScroll={handleScroll}
                className="rounded-xl h-[300px]"
                data={activityItems}
                itemContent={(index, items) => (
                  <ActivityRow
                    key={index}
                    item={items?.[0] as ActivityGetActivityByLeadIdResults}
                    expandedEmailItems={items && items?.length > 1 ? items : undefined}
                    last={index === activityItems.length - 1}
                    viewType="lead"
                    refetch={fetchActivities}
                    filters={filters}
                  />
                )}
              />
            )}
            {displayType === 'edit' &&
              leadLogs.results.map((item, index) => (
                <LogRow key={item?.id} item={item as AuditTrailLeadLog} last={index === leadLogs.results.length - 1} />
              ))}
            {displayType === 'assignment' &&
              (session.data?.currentRole?.is_admin || Boolean(session.data?.user.subordinatesCount)) &&
              mappedHistory.map((mh, index) => {
                return [
                  <AssignmentRow
                    key={index}
                    item={mh}
                    last={index === (mappedHistory.length || 1) - 1}
                    lead_name={assignmentHistory?.results?.[0]?.company.name}
                    type={mh.type as 'assigned' | 'unassigned'}
                  />
                ]
              }, [] as React.ReactNode[])}
            {loading && <CircularProgress className="absolute right-1/2 top-1/2" />}
            {count === 0 && displayType !== 'message' && (
              <Typography className="text-center w-full" variant="h6">
                No Activity
              </Typography>
            )}
          </Box>
        )}
        {displayType === 'file' && <Files type="Lead" id={leadId} onContractFileUpdateSuccess={() => refetchRequiredColValues?.()} />}
        {displayType === 'message' && <Messages messages={messages} fetchMessages={fetchMessages} leadId={leadId} />}
        {addNoteOpen && (
          <AddNote
            refetch={() => fetchActivities({ concat: false, activityType: filters.activityType })}
            open={addNoteOpen}
            setOpen={setAddNoteOpen}
          />
        )}
      </Collapse>
      <NewEmailDrawer
        open={Boolean(isNewEmailOpen?.isOpen)}
        defaultData={
          isNewEmailOpen?.requestInfo
            ? {
                to: ['natalia@sunrisefundinggrp.com'],
                subject: `Request Information on ${lead?.company.name?.toUpperCase()}`,
                body: 'Please provide more information: '
              }
            : undefined
        }
        refetch={() => {
          if (!filters.activityType || filters.activityType === 'EMAIL') {
            fetchActivities({ concat: false, activityType: filters.activityType })
          }
        }}
        setOpen={(o) => setNewEmailOpen && setNewEmailOpen(o ? { isOpen: true } : undefined)}
      />
    </Box>
  )
}

export default LeadActivity
