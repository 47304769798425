import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const useLeadDetailsSearchParams = (leadId?: string | undefined) => {
  const search = useLocation().search
  const [searchParams, setSearchParams] = useSearchParams(search)
  const modalLeadId = searchParams.get('modalLeadId')

  const handleClose = () => {
    searchParams.delete('modalLeadId')
    setSearchParams(searchParams)
  }

  const handleOpen = (leadIdParam?: string) => {
    if (leadId || leadIdParam) {
      const id = (leadId || leadIdParam) as string
      searchParams.set('modalLeadId', id)
      setSearchParams(searchParams)
    }
  }

  return {
    leadId: modalLeadId as string | undefined,
    handleClose,
    handleOpen
  }
}

export default useLeadDetailsSearchParams
