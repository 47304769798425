import './services/sentry'

import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { LocalizationProvider } from '@mui/x-date-pickers'
import './index.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Toaster } from 'react-hot-toast'
import { ToastContainer } from 'react-toastify'
import { StyledEngineProvider } from '@mui/material'
import { SettingsProvider } from './contexts/SettingsContext'
import App from './App'
import { SessionProvider } from 'next-auth/react'
import 'react-toastify/dist/ReactToastify.css'
import * as Sentry from '@sentry/react'

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

root.render(
  <Sentry.ErrorBoundary fallback={<h2>Something went wrong.</h2>}>
    <StyledEngineProvider injectFirst>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <SessionProvider>
              <Router>
                <Toaster
                  toastOptions={{
                    className: 'cy-toast',
                    style: {
                      wordBreak: 'break-all'
                    }
                  }}
                  position="top-center"
                />
                <ToastContainer position="top-right" />
                <App />
              </Router>
            </SessionProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </StyledEngineProvider>
  </Sentry.ErrorBoundary>
)
