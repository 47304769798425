import { Box, TextareaAutosize, Typography } from '@mui/material'
import { CallFeedbackContact, FollowupSchema } from '..'
import { useState } from 'react'
import { InterestedFieldsType } from '.'
import ContactInfo from '../ContactInfo'
import { UseFormReturn } from 'react-hook-form'
import TaskFollowup from '../TaskFollowup'
import LeadReassignmentButton from '../../../../../components/LeadReassignmentButton'

type ReviewProps = {
  contact: CallFeedbackContact | undefined
  setContactInfo: React.Dispatch<React.SetStateAction<CallFeedbackContact | undefined>>
  interestedFields: InterestedFieldsType
  setInterestedFields: React.Dispatch<React.SetStateAction<InterestedFieldsType>>
  form: UseFormReturn<FollowupSchema>
  reassign: boolean
  setReassign: React.Dispatch<React.SetStateAction<boolean>>
  leadId: string
  relatedLeads?: boolean
  stageReassignmentType: string
}

const Review = ({
  contact: contactInfo,
  setContactInfo,
  interestedFields,
  setInterestedFields,
  form,
  reassign,
  setReassign,
  leadId,
  relatedLeads,
  stageReassignmentType
}: ReviewProps) => {
  const [addFollowupNote, setAddFollowupNote] = useState(false)

  return (
    <Box className="pt-6">
      <Box className="">
        {contactInfo && <ContactInfo leadId={leadId} title="Contact Info" contactInfo={contactInfo} setContactInfo={setContactInfo} />}
      </Box>
      <TaskFollowup form={form} setAddFollowupNote={setAddFollowupNote} addFollowupNote={addFollowupNote} />
      <Typography className="text-center my-5 text-grey-600 text_sm_normal">or</Typography>
      <LeadReassignmentButton
        relatedLeads={relatedLeads}
        selected={reassign}
        setSelected={setReassign}
        stageReassignmentType={stageReassignmentType}
      />
      <Box className="mt-6">
        <Typography className="text-grey-700 mb-1.5">Call Notes</Typography>
        <TextareaAutosize
          placeholder="Enter a note..."
          className="p-3 w-full border-solid border-[1px] border-grey-300 rounded-lg"
          value={interestedFields.callNotes.value}
          onChange={(e) => setInterestedFields({ ...interestedFields, callNotes: { value: e.target.value } })}
        />
      </Box>
    </Box>
  )
}

export default Review
