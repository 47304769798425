import { Box, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../assets/icons'
import React, { ReactNode, SyntheticEvent } from 'react'
import { SvgIconComponent } from '@mui/icons-material'

export interface DialogHeaderProps {
  leadingAccessory: ReactNode
  trailingAccessory: ReactNode
}

export const DialogTitle = ({ title }: { title: ReactNode }) => (
  <Typography className="text-grey-900 text_lg_semibold flex items-center gap-2">{title}</Typography>
)

export const DialogCloseButton = (props: Omit<DialogButtonProps, 'Icon'>) => <DialogButton {...props} Icon={CloseIcon} />

interface DialogButtonProps {
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void
  Icon: SvgIconComponent
  disabled?: boolean
}

export const DialogButton = ({ onClick, Icon, disabled }: DialogButtonProps) => (
  <IconButton onClick={onClick} disabled={disabled}>
    <Icon fontSize="small" className="text-grey-500" />
  </IconButton>
)

export const DialogHeader = ({ leadingAccessory, trailingAccessory }: DialogHeaderProps) => (
  <Box className="flex justify-between items-start p-5">
    {leadingAccessory}

    <Box className="flex-shrink-0">{trailingAccessory}</Box>
  </Box>
)
