import { Dialog, Box, Typography, IconButton, TextField, Button, Stack, Tooltip } from '@mui/material'
import { AddCircleOutlineOutlinedIcon, CloseIcon, StarRounded } from '../assets/icons'
import { useState } from 'react'
import { trpc } from '../core/trpc'
import { Phone_Type } from '@prisma/client'
import { formatPhoneOnInput, getSplitPhones } from '../utils'
import { remove } from 'ramda'

type ModalProps = {
  closeModal: () => void
  initialValues: ContactToEdit
  refetch: () => void
}

type Phone = {
  id: string
  type: Phone_Type
  contact_id: string
  phone_number: string
  thumbs_up: number
  thumbs_down: number
  is_primary_phone: boolean
}

type ContactToEdit = {
  name: string
  role: string
  primary_email: string
  other_emails: string[]
  phones: Phone[]
  notes: string
  id: string
}

const EditContactModal = ({ closeModal, initialValues, refetch }: ModalProps) => {
  const [editContact, setEditContact] = useState<ContactToEdit>(initialValues)
  const editContactMutation = trpc.contact.update.useMutation()
  const deleteContactPhonesMutation = trpc.contactPhone.deletePhoneNumber.useMutation()
  const addContactPhoneMutation = trpc.contactPhone.create.useMutation()
  const editContactPhoneMutation = trpc.contactPhone.updatePhone.useMutation()

  const handleTextFieldChange = (fieldName: string, value: string, idx?: number) => {
    if (idx !== undefined) {
      const phoneToUpdate = editContact.phones.map((p, index) => {
        if (index === idx) {
          return { ...p, phone_number: value }
        }
        return p
      })
      setEditContact({ ...editContact, phones: phoneToUpdate })
    } else {
      setEditContact({ ...editContact, [fieldName]: value })
    }
  }

  const onSave = async () => {
    await editContactMutation.mutateAsync({ ...editContact, id: initialValues.id })
    await handleDeletedPhones()
    const isPrimaryAdded = await handleAddedPhones()
    await handleEditedPhones(isPrimaryAdded)
    refetch()
    closeModal()
  }

  const handleDeletedPhones = async () => {
    const editedPhones = editContact.phones.filter((p, idx) => p.id && initialValues.phones[idx].phone_number !== p.phone_number)
    let deletedPhones = initialValues.phones
      .filter((p) => p.id && !editContact?.phones.map((c) => c.phone_number).includes(p.phone_number))
      .map((p) => p.id)
    if (editedPhones) {
      deletedPhones = deletedPhones.filter((p) => !editedPhones.map((ep) => ep.id).includes(p))
    }
    for (const deletedPhone of deletedPhones) {
      await deleteContactPhonesMutation.mutateAsync({ phoneId: deletedPhone })
    }
  }
  const handleAddedPhones = async () => {
    let isSetPrimary = false
    const addedPhones = editContact?.phones.filter((p) => !p.id && !initialValues.phones.map((c) => c.id && c.phone_number).includes(p.phone_number))
    for (const addedPhone of addedPhones) {
      const primary_phone_index = editContact?.phones.findIndex((p) => p.is_primary_phone)
      if (!isSetPrimary && primary_phone_index === -1) {
        await addContactPhoneMutation.mutateAsync({
          contactId: initialValues.id,
          phoneNumber: addedPhone.phone_number,
          type: addedPhone.type,
          isPrimaryPhone: true
        })
        isSetPrimary = true
      } else {
        await addContactPhoneMutation.mutateAsync({
          contactId: initialValues.id,
          phoneNumber: addedPhone.phone_number,
          type: addedPhone.type,
          isPrimaryPhone: addedPhone.is_primary_phone
        })
      }
    }
    return isSetPrimary
  }

  const handleEditedPhones = async (isPrimaryAdded: boolean) => {
    const editedPhones = editContact?.phones.filter(
      (p, idx) =>
        (p.id && initialValues.phones[idx].phone_number !== p.phone_number) ||
        (p.id && initialValues.phones[idx].is_primary_phone !== p.is_primary_phone)
    )
    for (const editedPhone of editedPhones ?? []) {
      if (editedPhone.id) {
        await editContactPhoneMutation.mutateAsync({
          phoneNumber: editedPhone.phone_number,
          phoneId: editedPhone.id,
          isPrimaryPhone: editedPhone.is_primary_phone
        })
      }
    }

    const primary_phone_index = editContact?.phones.findIndex((p) => p.is_primary_phone)
    if (!isPrimaryAdded && editContact?.phones && primary_phone_index === -1 && editContact?.phones.length >= 1) {
      if (editContact?.phones[0].id) {
        await editContactPhoneMutation.mutateAsync({
          phoneNumber: editContact?.phones[0].phone_number,
          phoneId: editContact?.phones[0].id,
          isPrimaryPhone: true
        })
      }
    }
  }

  const handleAddPhone = (phone?: Phone) => {
    const initialPhone = phone ?? {
      id: '',
      type: Phone_Type.CALL,
      contact_id: editContact?.id ?? '',
      phone_number: '',
      thumbs_up: 0,
      thumbs_down: 0,
      is_primary_phone: false
    }
    setEditContact((prev) => ({
      ...prev,
      phones: [...prev.phones, initialPhone]
    }))
  }

  const setAsPrimaryPhone = (idx: number) => {
    setEditContact({
      ...editContact,
      phones: editContact.phones.map((phone, i) => (i === idx ? { ...phone, is_primary_phone: true } : { ...phone, is_primary_phone: false }))
    })
  }

  const handleRemovePhone = (idx: number) => {
    setEditContact({ ...editContact, phones: remove(idx, 1, editContact.phones) })
  }

  const handleRemoveEmail = (idx: number) => {
    setEditContact({ ...editContact, other_emails: remove(idx, 1, editContact.other_emails) })
  }

  const handleAddEmail = () => {
    setEditContact((prev) => ({
      ...prev,
      other_emails: [...prev.other_emails, '']
    }))
  }

  const handlePhoneInput = (phone: string, idx: number) => {
    const phoneNumbers = getSplitPhones(phone)
    handleTextFieldChange('phone_number', phoneNumbers[0], idx)
    phoneNumbers.slice(1).map((p) => {
      handleAddPhone(getPhoneObj(p))
    })
  }

  const getPhoneObj = (phoneNum: string) => {
    return {
      id: '',
      type: Phone_Type.CALL,
      contact_id: editContact?.id ?? '',
      phone_number: phoneNum,
      thumbs_up: 0,
      thumbs_down: 0,
      is_primary_phone: false
    }
  }

  return (
    <Dialog open={true}>
      <Box className="p-5 min-w-[340px]">
        <Box className="flex justify-between items-start mb-5">
          <Typography className="text-grey-900 text_lg_semibold">Edit Contact</Typography>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon fontSize="small" className="text-grey-500" />
          </IconButton>
        </Box>
        <Box>
          <Typography className="text-grey-700 mb-1.5">Name</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="name"
            value={editContact.name}
            onChange={(e) => handleTextFieldChange('name', e.target.value)}
          />
          <Typography className="text-grey-700 mb-1.5">Email</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="primary_email"
            value={editContact.primary_email}
            onChange={(e) => handleTextFieldChange('primary_email', e.target.value)}
          />
          <Stack gap="6px">
            {Boolean(editContact.other_emails.length) &&
              editContact.other_emails.map((email, index) => (
                <Box key={index} className="flex items-center">
                  <TextField
                    fullWidth
                    value={email}
                    onChange={(e) => {
                      setEditContact((data) => ({
                        ...data,
                        other_emails: data.other_emails.map((mail, idx) => {
                          if (idx === index) return e.target.value
                          return mail
                        })
                      }))
                    }}
                  />
                  <IconButton onClick={() => handleRemoveEmail(index)}>
                    <CloseIcon fontSize="medium" className="text-grey-400" />
                  </IconButton>
                </Box>
              ))}
            <Box className="flex justify-end w-full">
              <Button className="w-fit" onClick={handleAddEmail}>
                <AddCircleOutlineOutlinedIcon className="text-primary-500 mr-2" />
                <Typography className="text_sm_medium text-primary-500">Add Email</Typography>
              </Button>
            </Box>
          </Stack>
          <Typography className="text-grey-700 mb-1.5">Phone</Typography>
          {editContact.phones?.map((p, idx) => (
            <Box className="flex items-center mb-4">
              <TextField
                fullWidth
                name="phone"
                value={formatPhoneOnInput(p.phone_number)}
                onChange={(e) => {
                  handlePhoneInput(e.target.value, idx)
                }}
              />
              <IconButton onClick={() => setAsPrimaryPhone(idx)}>
                <Tooltip title="Mark as primary phone">
                  {p.is_primary_phone ? (
                    <StarRounded fontSize="medium" className="fill-warning-300" />
                  ) : (
                    <StarRounded fontSize="medium" className="text-grey-400" />
                  )}
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => handleRemovePhone(idx)}>
                <Tooltip title="Remove phone">
                  <CloseIcon fontSize="medium" className="text-grey-400" />
                </Tooltip>
              </IconButton>
            </Box>
          ))}
          <Box className="flex justify-end w-full">
            <Button onClick={() => handleAddPhone()}>
              <AddCircleOutlineOutlinedIcon className="text-primary-500 mr-2" />
              <Typography className="text_sm_medium text-primary-500">Add Phone</Typography>
            </Button>
          </Box>
          <Typography className="text-grey-700 mb-1.5">Role</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="role"
            value={editContact.role}
            onChange={(e) => handleTextFieldChange('role', e.target.value)}
          />
          <Typography className="text-grey-700 mb-1.5">Notes</Typography>
          <TextField
            className="mb-4"
            fullWidth
            name="notes"
            value={editContact.notes}
            onChange={(e) => handleTextFieldChange('notes', e.target.value)}
          />
        </Box>
        <Box className="flex justify-end gap-3">
          <Button
            variant="outlined"
            onClick={() => {
              closeModal()
              setEditContact(initialValues)
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => onSave()} disabled={!editContact.name}>
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
export default EditContactModal
