import { useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Paper, Button, Input, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'
import debounce from 'lodash.debounce'

const PREFIX = 'Search'

const classes = {
  root: `${PREFIX}-root`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  searchInput: `${PREFIX}-searchInput`,
  searchButton: `${PREFIX}-searchButton`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.search}`]: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #CDD5DF'
  },

  [`& .${classes.searchIcon}`]: {
    marginRight: theme.spacing(2)
  },

  [`& .${classes.searchInput}`]: {
    flexGrow: 1
  },

  [`& .${classes.searchButton}`]: {
    marginLeft: theme.spacing(2)
  }
}))

type IPropsSearch = {
  searchText?: string
  onSearch: (search: string) => void
  showButton?: boolean
  isDebounced?: boolean
  sx?: SxProps<Theme>
  placeholder?: string
}

function Search(props: IPropsSearch) {
  const { onSearch, showButton, isDebounced, searchText = '', placeholder = 'Search', ...rest } = props
  const [search, setSearch] = useState(searchText)
  const handleSearch = (value: string) => {
    setSearch(value)
  }

  const debounceSearch = useMemo(() => debounce((s) => onSearch(s), 1000), [onSearch])

  return (
    <Root {...rest}>
      <Paper className={classes.search} elevation={1}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          data-cy="searchInput"
          className={classes.searchInput}
          disableUnderline
          placeholder={placeholder}
          value={search}
          onChange={(e) => {
            handleSearch(e.target.value)
            if (isDebounced) {
              debounceSearch(e.target.value)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isDebounced) onSearch(search)
          }}
        />
      </Paper>
      {showButton && (
        <Button className={classes.searchButton} onClick={() => onSearch(search)} size="large" variant="contained">
          Search
        </Button>
      )}
    </Root>
  )
}

export default Search
