import { Box, Dialog, IconButton, Typography } from '@mui/material'
import { OfferPendingOffer } from '../../../types/procedureOutputs'
import { CloseIcon } from '../../../assets/icons'
import PendingOffer from './PendingOffer'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  offers: OfferPendingOffer[]
}

const PendingOffersModal = ({ open, setOpen, offers }: Props) => {
  return (
    <Dialog open={open}>
      <Box className="p-5 flex flex-col gap-4 w-[600px] mobile:w-auto">
        <Box className="flex justify-between items-center">
          <Typography className="text-grey-900 text_lg_semibold">Confirm Deals</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon className="text-grey-700" fontSize="small" />
          </IconButton>
        </Box>
        <Typography className="text-grey-700 mb-1.5">You have funded deals pending. Your action is required to confirm deals.</Typography>
        {offers.map((o) => (
          <PendingOffer key={o.id} offer={o} />
        ))}
      </Box>
    </Dialog>
  )
}

export default PendingOffersModal
