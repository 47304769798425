import React, { useState, useMemo } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { trpc } from '../core/trpc'
import debounce from 'lodash.debounce'

type Props = {
  reason: string
  onChange: (reason: string) => void
}

const RefundReasonAutocomplete = ({ reason, onChange }: Props) => {
  const [search, setSearch] = useState('')
  const { data } = trpc.lead.getRefundReasons.useQuery({ search })
  const reasonList: string[] = useMemo(() => (data?.map((d) => d.refund_reason) || []).filter((reason) => reason !== null) as string[], [data])
  const debouncedSearch = useMemo(() => debounce((search: string) => setSearch(search), 300), [])

  return (
    <Autocomplete
      freeSolo
      getOptionLabel={(option) => option}
      value={reason}
      onChange={(event, newValue) => {
        if (newValue === null) {
					onChange('')
					return
        }
        if (newValue.startsWith('Add new refund reason')) {
          onChange(newValue.replace('Add new refund reason "', '').slice(0, -1))
        } else {
          onChange(newValue)
        }
      }}
      filterOptions={(options, params) => {
        const { inputValue } = params
        const isExisting = options.some((option) => inputValue && option.toLowerCase() === inputValue.toLowerCase())
        if (inputValue !== '' && !isExisting) {
          options.push(`Add new refund reason "${inputValue}"`)
        }
        return options
      }}
      options={reasonList || []}
      renderInput={(params) => <TextField {...params} />}
      onInputChange={(_, value) => debouncedSearch(value)}
    />
  )
}

export default RefundReasonAutocomplete
