import { AccountingUserType } from '../types/procedureOutputs'

export const AccountingUserTypeObject = {
  PARTNER: 'PARTNER',
  SUBSIDIARY: 'SUBSIDIARY',
  LEAD_FEE: 'LEAD_FEE',
  ADMIN_FEE: 'ADMIN_FEE',
  TEAM_LEAD: 'TEAM_LEAD',
  BROKER: 'BROKER',
  ISO: 'ISO'
} as Record<AccountingUserType, AccountingUserType>
