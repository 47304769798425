import axios from 'axios'
import { FileOutput } from '../types/procedureOutputs'

export async function downloadFileFromLink(file: FileOutput, url: string) {
  // this is needed to rename the file stored in azure
  const response = await axios.get<ArrayBuffer>(url, { responseType: 'arraybuffer' })
  const objectUrl = URL.createObjectURL(new Blob([response.data], { type: file.type }))
  const el = document.createElement('a')
  el.href = objectUrl
  el.download = file.name
  el.hidden = true
  el.click()
  el.remove()
}
