import { Typography, Box, IconButton, Tooltip, Menu, MenuItem } from '@mui/material'
import { Offer } from '.'
import { useContext, useMemo, useState } from 'react'
import { AbilityContext } from '../../contexts/AbilityContext'
import { useNavigate, useParams } from 'react-router'
import MaterialReactTable, { MRT_ColumnDef, MRT_SortingState } from 'material-react-table'
import OfferActions from './OfferActions'
import { format } from 'date-fns'
import OfferDetails from './offerDetails'
import { trpc } from '../../core/trpc'
import { Mail } from 'react-feather'
import EmailDrawer from '../pipelines/leadDetails/activity/EmailDrawer'
import { OfferStatus } from '@prisma/client'
import { Decimal } from 'decimal.js'

type OfferListProps = {
  showDate?: boolean
  inLeadDetails?: boolean
  lead_id?: string
  refetch?: () => void
  search?: string
  statuses?: OfferStatus[]
  pipelineId?: string
  stageId?: string
}

const OfferList = ({ showDate, inLeadDetails, refetch, search = '', lead_id, statuses, pipelineId, stageId }: OfferListProps) => {
  const [emailId, setEmailId] = useState<string>()
  const navigate = useNavigate()
  const [offerId, setOfferId] = useState('')
  const [emailOptionsOffer, setEmailOptionsOffer] = useState<{ offer: Offer; anchorEl: HTMLElement } | undefined>()
  const ability = useContext(AbilityContext)
  const canEditOffer = ability.can('edit', 'Offer')
  const canViewOffer = ability.can('view', 'Offer')
  const TAKE = 20
  const [skip, setSkip] = useState(0)

  const { data: offersData, refetch: refetchOffers } = trpc.offer.getOffers.useQuery(
    { leadId: lead_id || '', search, take: TAKE, skip, statuses },
    { enabled: Boolean(lead_id && canViewOffer) }
  )

  const refetchAll = () => {
    refetch?.()
    if (lead_id && canViewOffer) {
      refetchOffers()
    }
  }

  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const { offerId: offerIdParams } = useParams()
  const tableCellData = useMemo<MRT_ColumnDef<Offer>[]>(() => {
    return [
      {
        header: '',
        size: 0,
        grow: false,
        id: 'email_id',
        accessorFn: (row: Offer) => {
          if (row.emails.length) {
            return (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setEmailOptionsOffer({ offer: row, anchorEl: e.currentTarget })
                }}
              >
                <Mail width={18} />
              </IconButton>
            )
          }
          return ''
        }
      },
      {
        header: '',
        Header: <Box className="">Funder Name</Box>,
        size: inLeadDetails ? 90 : undefined,
        id: 'funder_name',
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        accessorFn: (row: Offer) => (
          <Box data-cy="funderName" className="ml-2 uppercase">
            {row.funder.name}
          </Box>
        )
      },
      {
        header: 'Amt Offered',
        id: 'amount_offered',
        enableSorting: true,
        size: inLeadDetails ? 100 : undefined,
        accessorFn: (row: Offer) => (
          <Box>
            {row.amount_offered ? `${new Decimal(row.amount_offered || 0).div(1000)}K ` : ''}
            {row.term_frequency?.toLowerCase()}
          </Box>
        )
      },
      {
        header: 'Term',
        id: 'term',
        size: inLeadDetails ? 60 : undefined,
        enableSorting: false,
        accessorFn: (row: Offer) => <Typography component="span">{`${row.term_amount || ''}${row.term_length?.toLowerCase()?.[0] || ''}`}</Typography>
      },
      {
        header: 'Cmsn Pts',
        id: 'commission_points',
        size: inLeadDetails ? 80 : undefined,
        enableSorting: false,
        accessorFn: (row: Offer) => <Typography component="span">{row.commission_points ? `${row.commission_points}%` : ''}</Typography>
      },
      {
        header: 'Buy Rate',
        id: 'buy_rate',
        size: inLeadDetails ? 100 : undefined,
        enableSorting: false,
        accessorFn: (row: Offer) => <Typography component="span">{row.buy_rate || ''}</Typography>
      },
      {
        header: 'Date Updated',
        id: 'date',
        size: inLeadDetails ? 100 : undefined,
        enableSorting: true,
        accessorFn: (row: Offer) => (
          <Typography component="span">{row.updated_at ? `${format(new Date(row.updated_at), 'MM/dd/yyyy')}` : ''}</Typography>
        )
      },
      {
        header: 'Status',
        id: 'status',
        enableSorting: false,
        size: inLeadDetails ? 120 : undefined,
        accessorFn: (row: Offer) => {
          if (!canEditOffer) return null
          return (
            <Box>
              <OfferActions offer={row} refetch={refetchAll} compact pipelineId={pipelineId} stageId={stageId} />
            </Box>
          )
        }
      },
      {
        header: 'Reason for Decline',
        id: 'reason_for_decline',
        enableSorting: false,
        accessorFn: (row: Offer) => (
          <Tooltip title={row.reason_for_decline}>
            <Typography className="text_sm_normal truncate">{row.reason_for_decline}</Typography>
          </Tooltip>
        )
      }
    ]
  }, [])

  const noPaginationNeeded = useMemo(() => Boolean(!offersData || offersData?.count === offersData.results.length), [offersData])

  return (
    <Box>
      <Box className="overflow-auto bg-white">
        <MaterialReactTable
          initialState={{ pagination: { pageSize: TAKE, pageIndex: 1 }, columnVisibility: { buy_rate: !inLeadDetails } }}
          data={offersData?.results || []}
          columns={tableCellData}
          enableColumnDragging={false}
          enableColumnOrdering={false}
          enableColumnFilters={false}
          enableDensityToggle={false}
          enableSortingRemoval={false}
          enableSelectAll={true}
          enableFilters={false}
          enableColumnActions={false}
          enableRowVirtualization
          manualSorting
          enableTopToolbar={false}
          enableSorting
          enableMultiSort
          muiTableHeadRowProps={{ sx: { '&.MuiTableRow-root': { boxShadow: 'none' } } }}
          muiTableHeadCellProps={{
            align: 'center',
          }}
          muiTableBodyCellProps={{
            align: 'center',
            sx: { paddingY: inLeadDetails ? '0' : '5px', paddingX: inLeadDetails ? '0' : '10px', color: '#4B5565', fontSize: '16px', fontWeight: 400 }
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              if (inLeadDetails) setOfferId(row.original.id)
              else navigate(`/offers/${row.original.id}`)
            },
            sx: { cursor: 'pointer', background: 'white' }
          })}
          muiTablePaginationProps={{
            page: Math.floor(skip / TAKE),
            onPageChange: (event, page) => {
              setSkip(page * TAKE)
            },
            showFirstButton: false,
            showLastButton: false,
            rowsPerPageOptions: [TAKE]
          }}
          manualPagination={true}
          rowCount={offersData?.count}
          enableTableFooter={!noPaginationNeeded}
          enablePagination={!noPaginationNeeded}
          enableBottomToolbar={!noPaginationNeeded}
          state={{ sorting, columnPinning: inLeadDetails ? { right: ['status', 'reason_for_decline'] } : {} }}
          onSortingChange={setSorting}
        />
      </Box>
      {Boolean(offerIdParams || offerId) && (
        <OfferDetails
          handleClose={() => {
            if (inLeadDetails) setOfferId('')
            else navigate('/offers')
          }}
          offerId={offerIdParams || offerId}
          refetch={refetchAll}
        />
      )}
      <EmailDrawer maxZindex emailId={emailId} setEmailId={setEmailId} />
      {!!emailOptionsOffer && (
        <Menu anchorEl={emailOptionsOffer.anchorEl} open onClose={() => setEmailOptionsOffer(undefined)}>
          {emailOptionsOffer.offer.emails.map((email) => (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                setEmailId(email.id)
                setEmailOptionsOffer(undefined)
              }}
            >
              {email.subject}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  )
}

export default OfferList
