import { Typography, Box } from '@mui/material'
import { AlertCircle } from 'react-feather'
import { trpc } from '../../../core/trpc'
import toast from 'react-hot-toast'
import { LoadingButton } from '@mui/lab'
import { OfferGetOfferById } from '../../../types/procedureOutputs'

type Props = {
  offer: OfferGetOfferById
  refetch: () => void
}

const PendingOfferSideDrawer = ({ offer, refetch }: Props) => {
  const mutation = trpc.offer.sendOfferBackToInvoicing.useMutation({
    onSuccess: () => {
      toast.success('Thank you for completing the changes.')
      refetch()
    }
  })
  return (
    <Box className="bg-error-500 flex gap-3 rounded-lg px-5 py-1 items-center w-full justify-between">
      <Box className="flex gap-3">
        <AlertCircle className="text-error-100 h-5 w-5" />
        <Typography className="text_md_medium text-error-25">{`This deal is pending: ${offer?.pending_broker_reason}`}</Typography>
      </Box>
      <LoadingButton className="bg-white" loading={mutation.isLoading} onClick={() => mutation.mutate({ offerId: offer?.id || '' })}>
        Complete
      </LoadingButton>
    </Box>
  )
}

export default PendingOfferSideDrawer
