import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { features } from '../../../consts/features'

type SettingsPage = 'pipelines' | 'users' | 'roles' | 'accounting' | 'validation' | 'auto-stage' | 'export'

type SettingsButtonGroupProps = {
  defaultVal: SettingsPage
}

const SettingsButtonGroup = ({ defaultVal }: SettingsButtonGroupProps) => {
  const navigate = useNavigate()
  const [setting, setSetting] = useState<SettingsPage>(defaultVal)
  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: SettingsPage) => {
    if (value !== null) {
      setSetting(value)
      navigate(`/settings/${value}`)
    }
  }
  return (
    <ToggleButtonGroup className="bg-white shadow-sm" color="primary" exclusive value={setting} onChange={handleChange}>
      <ToggleButton className="capitalize px-4" value="pipelines">
        Pipelines
      </ToggleButton>
      <ToggleButton className="capitalize px-4" value="users">
        Users
      </ToggleButton>
      <ToggleButton className="capitalize px-4" value="validation">
        Validation
      </ToggleButton>
      <ToggleButton className="capitalize px-4" value="auto-stage">
        Auto-stage
      </ToggleButton>
      <ToggleButton className="capitalize px-4" value="roles">
        Roles
      </ToggleButton>
      <ToggleButton className="capitalize px-4" value="export">
        Export
      </ToggleButton>
      {features.accounting.settings && (
        <ToggleButton className="capitalize px-4" value="accounting">
          Accounting
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  )
}

export default SettingsButtonGroup
