import React, { ReactNode } from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'

export interface DialogSheetProps {
  className?: string
  children?: ReactNode | undefined
}

export const DialogSheet = ({ children, className, ...rest }: DialogSheetProps) => {
  return (
    <Box className={clsx('bg-white rounded-lg overflow-hidden mobile:w-full', className)} {...rest}>
      {children}
    </Box>
  )
}
