import axios from 'axios'
import { SendEmailPayload, FileAttachment } from './mailer/provider'
import { ContainerClient } from '@azure/storage-blob'
import toast from 'react-hot-toast'
import { MicrosoftAttachment } from '@libs/azure-graph'
import { v4 as uuidv4 } from 'uuid'
import { append, splitEvery } from 'ramda'

const graphAxios = axios.create({
  baseURL: 'https://graph.microsoft.com/v1.0/',
  headers: {
    'Content-Type': 'application/json'
  }
})

const getMsEmails = async ({
  mailboxEmailAddress,
  search,
  skip,
  mailFolder,
  onlyUnread,
  take
}: {
  search: string
  skip: number
  mailboxEmailAddress: string
  mailFolder?: 'Inbox' | 'SentItems'
  onlyUnread?: boolean
  take?: number
}) => {
  const unreadFilter = onlyUnread ? ' and isRead eq false' : ''
  const searchParams = search.length > 0 ? `&$search=${search}` : ''
  const filterParams = !search.length ? `&$filter=isDraft ne true${unreadFilter}&$skip=${skip}` : ''
  const folder = mailFolder ? `/mailFolders/${mailFolder}` : ''
  const finalTake = take ?? '15'
  const topParams = `&$top=${finalTake}`
  const params = `${searchParams}${filterParams}${topParams}`

  const { data } = await graphAxios.get<{ value: MicrosoftEmail[]; '@odata.count': number }>(
    `/users/${mailboxEmailAddress}${folder}/messages?$select=sender,isRead,hasAttachments,subject,internetMessageHeaders,conversationId,body,bodyPreview,bccRecipients,ccRecipients,createdDateTime,from,parentFolderId,toRecipients,internetMessageId,id,receivedDateTime,sentDateTime&$count=true${params}`
  )
  const count = data['@odata.count'] !== undefined ? data['@odata.count'] : data.value.length
  return { data: { value: data.value, '@odata.count': count } }
}

const getMsEmailById = async ({ microsoftId, mailboxEmailAddress }: { microsoftId: string; mailboxEmailAddress: string }) => {
  const { data } = await graphAxios.get<MicrosoftEmail>(
    `/users/${mailboxEmailAddress}/messages/${microsoftId}?$select=sender,isRead,hasAttachments,subject,internetMessageHeaders,conversationId,body,bodyPreview,bccRecipients,ccRecipients,createdDateTime,from,parentFolderId,toRecipients,internetMessageId,id`
  )
  const { data: attachmentRes } = await graphAxios.get<{ value: MicrosoftEmailAttachment[] }>(
    `/users/${mailboxEmailAddress}/messages/${microsoftId}/attachments`
  )
  return { data, attachments: attachmentRes.value }
}

const getMsUserPhoto = async ({ userId }: { userId: string }) => {
  const data = await graphAxios.get<Blob>(`/users/${userId}/photo/$value`, { responseType: 'blob' })
  return data
}

const updateMsUserPhoto = async ({ file }: { file: File }) => {
  const data = await graphAxios.put(`/me/photo/$value`, file, { headers: { 'Content-Type': 'image/jpeg' } })
  return data
}

const getUnreadEmailsCount = async ({
  search,
  mailFolder,
  mailboxEmailAddress
}: {
  search: string
  mailFolder?: 'Inbox'
  mailboxEmailAddress: string
}) => {
  const params = search.length > 0 ? `$search=${search}&$filter=isRead eq false` : `$filter=isDraft ne true and isRead eq false`
  const folder = mailFolder ? `/mailFolders/${mailFolder}` : ''
  const { data } = await graphAxios.get<{ '@odata.count': number }>(`/users/${mailboxEmailAddress}${folder}/messages?$count=true&$top=1&${params}`)
  return data['@odata.count']
}

const getMsUserContacts = async (search: string, mailboxEmailAddress: string) => {
  const res = await graphAxios.get<{ value: MicrosoftPeople[] }>(`/users/${mailboxEmailAddress}/people`, {
    params: {
      $search: search ? `"${search}"` : undefined,
      $filter: "personType/class eq 'Person'"
    }
  })
  return res.data
}

const getRecentMsEmail = async ({ mailboxEmailAddress }: { mailboxEmailAddress: string }) => {
  const { data } = await graphAxios.get<{ value: MicrosoftEmail[] }>(`/users/${mailboxEmailAddress}/messages?$orderBy=createdDateTime desc&$top=1`)
  return { data }
}

// trackingLink -  i.e ${config.apiUrl}/api/t?sender_address=${sender_address}&tracking_id=${uuid()}
export const injectTrackingToHTML = (html: string, trackingLink: string) => {
  const trackingHTML = `<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"><tbody><tr><td style="width:0px;"><img id="trackingImg" alt="" src="${trackingLink}" style="border:0;display:block;outline:none;text-decoration:none;height:0px;width:100%;font-size:13px;" width="0" height="0"/></td></tr></tbody></table>`
  return `<body>${html}${trackingHTML}</body>`
}

const sendMsEmail = async ({
  emailPayload,
  pubId,
  mailboxEmailAddress
}: {
  emailPayload: SendEmailPayload
  pubId?: string
  mailboxEmailAddress: string
}) => {
  const trackingId = uuidv4()
  const trackingLink = `${window.location.origin}/api/t?sender_address=${emailPayload.from.emailAddress.address}&tracking_id=${trackingId}`
  const message = {
    subject: emailPayload.subject,
    body: {
      content: emailPayload.bodyType === 'text' ? emailPayload.body : injectTrackingToHTML(emailPayload.body, trackingLink),
      contentType: emailPayload.bodyType || 'html'
    },
    toRecipients: emailPayload.to,
    ccRecipients: emailPayload.cc,
    bccRecipients: emailPayload.bcc,
    attachments: emailPayload.attachments?.length
      ? emailPayload.attachments.map((attachment) => ({ ...attachment, '@odata.type': '#microsoft.graph.fileAttachment' }))
      : undefined,
    internetMessageHeaders: [
      {
        name: 'x-pub-id',
        value: pubId
      },
      {
        name: 'x-tracking-id',
        value: trackingId
      }
    ]
  }

  const response = await graphAxios.post<MicrosoftEmail>(`users/${mailboxEmailAddress}/sendMail`, {
    message
  })
  return response
}

const getEmailId = async (pubId: string, mailboxEmailAddress: string) => {
  const getEmailsEndpoint: { data: { value: MicrosoftEmail[] } } = await graphAxios.get(
    `/users/${mailboxEmailAddress}/mailFolders/SentItems/messages?$select=sender,isRead,hasAttachments,subject,internetMessageHeaders,conversationId,body,bodyPreview,bccRecipients,ccRecipients,createdDateTime,from,parentFolderId,toRecipients,internetMessageId`
  )
  if (getEmailsEndpoint.data.value) {
    const emailData = getEmailsEndpoint.data.value.find((d) => d.internetMessageHeaders?.some((i: MicrosoftInternetHeaders) => i.value === pubId))
    if (emailData) {
      return emailData ?? null
    }
  }
  return null
}

const getMyEmailByInternetMessageId = (internetMessageId: string, mailboxEmailAddress: string) => {
  return graphAxios.get<{ value: MicrosoftEmail[] }>(`/users/${mailboxEmailAddress}/messages`, {
    params: {
      $filter: `internetMessageId eq '${internetMessageId}'`
    }
  })
}

const markEmailStatusInBackground = async (mailboxEmailAddress: string, emails: MicrosoftEmail[], isRead: boolean) => {
  const messageIds = emails.map((message) => message.id)
  const chunks = splitEvery(20, messageIds)

  for (const chunk of chunks) {
    const batchRequestBody = {
      requests: chunk.map((messageId, index) => ({
        id: index.toString(),
        method: 'PATCH',
        url: `/users/${mailboxEmailAddress}/messages/${messageId}`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          isRead: isRead
        }
      }))
    }

    await graphAxios.post(`$batch`, batchRequestBody)
  }
}

const markEmailReadStatus = async (mailboxEmailAddress: string, conversationId: string, isRead: boolean) => {
  const { data } = await graphAxios.get<{ value: MicrosoftEmail[] }>(
    `/users/${mailboxEmailAddress}/mailFolders/Inbox/messages?$filter=conversationId eq '${conversationId}' and isRead eq ${
      isRead ? 'false' : 'true'
    }`
  )

  markEmailStatusInBackground(mailboxEmailAddress, data.value, isRead)

  return { data: data.value }
}

const updateMsEmail = async ({
  data,
  emailId,
  mailboxEmailAddress
}: {
  data: Partial<MicrosoftEmail>
  emailId: string
  mailboxEmailAddress: string
}) => {
  const response = await graphAxios.patch<MicrosoftEmail>(`/users/${mailboxEmailAddress}/messages/${emailId}`, data)
  return { data: response.data }
}

const forwardMsEmail = async ({
  emailId,
  toRecipients,
  body,
  ccRecipients,
  mailboxEmailAddress,
  attachments
}: {
  mailboxEmailAddress: string
  emailId: string
  ccRecipients?: MicrosoftEmail['ccRecipients']
  toRecipients: MicrosoftEmail['toRecipients']
  body: string
  attachments?: FileAttachment[]
}) => {
  const response = await graphAxios.post<MicrosoftEmail>(`/users/${mailboxEmailAddress}/messages/${emailId}/createForward`, {
    message: {
      toRecipients,
      ccRecipients,
      attachments: attachments?.length
        ? attachments.map((attachment) => ({ ...attachment, '@odata.type': '#microsoft.graph.fileAttachment' }))
        : undefined,
      body: {
        contentType: 'HTML',
        content: body
      }
    }
  })

  if (response?.status === 201) {
    return await graphAxios.post(`/users/${mailboxEmailAddress}/messages/${response.data.id}/send`)
  } else return { status: response.status, statusText: response.statusText }
}

const getForwardedMessageId = async (now: Date, mailboxEmailAddress: string) => {
  const searchResponse = await graphAxios.get<{ value: MicrosoftEmail[] }>(`/users/${mailboxEmailAddress}/messages`, {
    params: {
      filter: `sentDateTime ge ${now.toISOString()}  and contains(subject,'fw')`
    }
  })
  return searchResponse.data.value[0]
}

/**
 * Move email to deleted items folder
 */
const softDeleteMsMessage = async ({ emailId, mailboxEmailAddress }: { emailId: string; mailboxEmailAddress: string }) => {
  const response = await graphAxios.post(`/users/${mailboxEmailAddress}/messages/${emailId}/move`, {
    destinationId: 'deleteditems'
  })
  return { data: response.status }
}

const deleteMsAttachment = async ({ id, emailId, mailboxEmailAddress }: { id?: string; emailId: string; mailboxEmailAddress: string }) => {
  const attachementParam = id ? `/${id}` : ''
  const response = await graphAxios.delete(`/users/${mailboxEmailAddress}/messages/${emailId}/attachments${attachementParam}`)
  return { data: response.status } // 204 No-Content
}

const replyMsEmail = async ({
  replyAll,
  comment,
  emailId,
  message,
  attachments,
  mailboxEmailAddress
}: {
  replyAll?: boolean
  comment: string
  emailId: string
  message?: Partial<MicrosoftEmail>
  attachments?: FileAttachment[]
  mailboxEmailAddress: string
}) => {
  const trackingId = uuidv4()
  console.log('trackingId:', trackingId)
  const trackingLink = `${window.location.origin}/api/t?sender_address=${mailboxEmailAddress}&tracking_id=${trackingId}`
  const injectedHTML = injectTrackingToHTML(comment, trackingLink)
  const response = await graphAxios.post(`/users/${mailboxEmailAddress}/messages/${emailId}/${replyAll ? 'replyAll' : 'reply'}`, {
    comment: injectedHTML,
    message: {
      ...message,
      attachments: attachments?.length
        ? attachments.map((attachment) => ({ ...attachment, '@odata.type': '#microsoft.graph.fileAttachment' }))
        : undefined,
      internetMessageHeaders: append(
        {
          name: 'x-tracking-id',
          value: trackingId
        },
        message?.internetMessageHeaders || []
      )
    }
  })
  return { ...response }
}

const getMsEmailAttachments = async ({
  emailId,
  mailboxEmailAddress,
  includeInline = false
}: {
  emailId: string
  mailboxEmailAddress: string
  includeInline?: boolean
}) => {
  const response = await graphAxios.get<{ value: MicrosoftAttachment[] }>(`users/${mailboxEmailAddress}/messages/${emailId}/attachments`)
  return {
    data: {
      value: response.data?.value.filter((attach) => includeInline || !attach.isInline)
    }
  }
}

const uploadFileToAzure = async ({
  contents,
  size,
  filePath,
  containerUrl,
  sasQuery
}: {
  contents: string
  size: number
  filePath: string
  containerUrl: string | undefined
  sasQuery: string | undefined
}) => {
  if (containerUrl && sasQuery) {
    const container = new ContainerClient(containerUrl.concat('?').concat(sasQuery))
    try {
      const data = await fetch(contents)
      const blob = await data.blob()
      const { response } = await container.uploadBlockBlob(filePath, blob, size)
      if (response.errorCode) throw new Error('Error uploading file')
      return response
    } catch (error) {
      toast.error(`Failed to create file. ${error}`)
      throw Error(`failed to create file ${error}`)
    }
  } else {
    throw Error(`Failed to get upload link`)
  }
}

const uploadEmailContentToAzure = async ({
  contents,
  size,
  filePath,
  containerUrl,
  sasQuery
}: {
  contents: string
  size: number
  filePath: string
  containerUrl: string | undefined
  sasQuery: string | undefined
}) => {
  if (containerUrl && sasQuery) {
    const container = new ContainerClient(containerUrl.concat('?').concat(sasQuery))
    const { response } = await container.uploadBlockBlob(filePath, contents, size)
    if (response.errorCode) throw new Error('Error uploading file')
    return response
  }
}
export {
  graphAxios,
  getMsEmails,
  getUnreadEmailsCount,
  sendMsEmail,
  forwardMsEmail,
  getRecentMsEmail,
  markEmailReadStatus,
  updateMsEmail,
  replyMsEmail,
  getMsEmailAttachments,
  uploadEmailContentToAzure,
  uploadFileToAzure,
  getMsEmailById,
  softDeleteMsMessage,
  getEmailId,
  getForwardedMessageId,
  getMsUserContacts,
  deleteMsAttachment,
  getMyEmailByInternetMessageId,
  getMsUserPhoto,
  updateMsUserPhoto
}
