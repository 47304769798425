import { Autocomplete, Box, Button, Dialog, Divider, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import { CloseIcon } from '../../assets/icons'
import { useMemo, useState } from 'react'
import { trpc } from '../../core/trpc'
import debounce from 'lodash.debounce'
import { FunderGetFundersOutput, LeadsBySearch } from '../../types/procedureOutputs'
import { LoadingButton } from '@mui/lab'
import toast from 'react-hot-toast'
import { TermFrequency, TermLength } from '@prisma/client'
import { capitalize, validateBuyRate } from '../../utils/helpers'
import FilePicker from '../../components/FilePicker'
import { ContainerClient } from '@azure/storage-blob'
import { InformationCircleOutlined } from '../../icons/information-circle-outlined'

type Lead = LeadsBySearch['results'][number]
type Funder = FunderGetFundersOutput['results'][number]

type CreateOffer = {
  lead: Lead | null
  funder: Funder | null
  amount_offered: string
  term_amount: string
  term_length: TermLength | null
  term_frequency: TermFrequency | null
  commission_points: string
  buy_rate: string
  service_fee: string
  period_payment_amount: string
}

type AddOfferProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  refetch: () => void
  leadId?: string
}

const AddOffer = ({ open, setOpen, refetch, leadId }: AddOfferProps) => {
  const [leadSearch, setLeadSearch] = useState('')
  const [funderSearch, setFunderSearch] = useState('')
  const [files, setFiles] = useState<File[] | undefined>()
  const [offer, setOffer] = useState<CreateOffer>({
    lead: null,
    funder: null,
    amount_offered: '',
    term_frequency: null,
    term_amount: '',
    term_length: null,
    commission_points: '',
    buy_rate: '',
    service_fee: '',
    period_payment_amount: ''
  })

  const { data: offerDrafts } = trpc.shopDeal.getOfferDraftsByLead.useQuery({ lead_id: leadId || '' }, { enabled: Boolean(leadId) })
  const { data: leadsData } = trpc.lead.getLeadsBySearch.useQuery({ search: leadSearch })
  const { data: fundersData } = trpc.funder.getFunders.useQuery({ search: funderSearch })
  const { data: termFrequencies } = trpc.offer.getTermFrequencies.useQuery()

  const createFileMutation = trpc.file.createOfferFile.useMutation()
  const getLinks = trpc.file.createUploadFileLink.useMutation()
  const trpcContext = trpc.useContext()

  const saveFiles = async (id: string) => {
    if (!files) return
    try {
      const { containerUrl, sasQuery } = (await getLinks.mutateAsync()) || {}
      if (containerUrl && sasQuery) {
        for (const file of files) {
          const arrayBufferFile = await file.arrayBuffer()
          const filePath = `Offer/${id}/${new Date().getTime()}-${file.name}`
          const container = new ContainerClient(containerUrl.concat('?').concat(sasQuery))

          await container.uploadBlockBlob(filePath, arrayBufferFile, arrayBufferFile.byteLength)

          await createFileMutation.mutateAsync({
            offer_id: id,
            name: file.name,
            url: filePath,
            type: file.type,
            size: arrayBufferFile.byteLength
          })
        }
      }
    } catch (e) {
      toast.error(`Failed to create file. ${e}`)
    }
  }

  const mutation = trpc.offer.createOffer.useMutation({
    onSuccess: async (offer) => {
      toast.success('Offer successfully created')
      if (files) {
        await saveFiles(offer.id).catch((e) => toast.error(e.message))
      }
      refetch()
      trpcContext.offer.getOffers.fetch({ leadId: offer.lead_id, skip: 0, take: 20 })
      setOpen(false)
    },
    onError: (e) => toast.error(e.message)
  })

  const handleInputChange = (value: string, type: 'lead' | 'funder') => {
    debouncedSearch(value, type)
  }

  const debouncedSearch = useMemo(
    () =>
      debounce((search: string, type: 'lead' | 'funder') => {
        if (type === 'lead') {
          setLeadSearch(search)
        } else {
          setFunderSearch(search)
        }
      }, 500),
    []
  )

  const handleSave = () => {
    mutation.mutate({
      lead_id: leadId || offer.lead?.id || '',
      funder_id: offer.funder?.id || '',
      amount_offered: Number(offer.amount_offered),
      term_amount: Number(offer.term_amount),
      term_length: offer.term_length || undefined,
      term_frequency: offer.term_frequency || undefined,
      buy_rate: Number(offer.buy_rate),
      commission_points: Number(offer.commission_points) || undefined,
      service_fee: Number(offer.service_fee) || undefined,
      period_payment_amount: Number(offer.period_payment_amount)
    })
  }

  const termLengths = Object.values(TermLength)
  const termLengthOptions = ['30', '60', '90', '180', '365'] as const
  const funderOptions =
    fundersData?.results.filter((funder) => !offerDrafts?.length || !offerDrafts.find((draft) => draft.funder_id === funder.id)) || []

  return (
    <Dialog open={open}>
      <Box className="flex flex-col gap-6 p-5 w-full">
        <Box className="flex items-center justify-between mb-6">
          <Typography className="text_xl_medium text-grey-900">Add Offer</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        {!leadId && (
          <Box>
            <Typography className="text-grey-700 mb-1.5">Select Lead</Typography>
            <Autocomplete
              fullWidth
              onInputChange={(_, v) => handleInputChange(v, 'lead')}
              value={offer.lead}
              getOptionLabel={(option) => option.company.name.toUpperCase()}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={leadsData?.results || []}
              onChange={(_, v) => setOffer((offer) => ({ ...offer, lead: v }))}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        )}
        <Box>
          <Typography className="text-grey-700 mb-1.5">Funder Name</Typography>
          <Autocomplete
            fullWidth
            onInputChange={(_, v) => handleInputChange(v, 'funder')}
            value={offer.funder}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={funderOptions}
            onChange={(_, v) => setOffer((offer) => ({ ...offer, funder: v }))}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box>
          <Typography className="text-grey-700 mb-1.5">Commission Points</Typography>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            error={Number(offer.commission_points) > 100}
            helperText={Number(offer.commission_points) > 100 ? 'Commission cannot be greater than 100%' : ''}
            onWheel={(e) => e.preventDefault()}
            type="number"
            fullWidth
            value={offer.commission_points}
            InputProps={{ endAdornment: <Typography className="mr-1">%</Typography> }}
            onChange={(e) => setOffer((offer) => ({ ...offer, commission_points: e.target.value }))}
          />
        </Box>
        <Box>
          <Typography className="text-grey-700 mb-1.5">Amount Offered</Typography>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            onWheel={(e) => e.preventDefault()}
            type="number"
            fullWidth
            value={offer.amount_offered}
            InputProps={{ startAdornment: <Typography className="mr-1">$</Typography> }}
            onChange={(e) => setOffer((offer) => ({ ...offer, amount_offered: e.target.value }))}
          />
        </Box>
        <Box>
          <Box className="flex">
            <Typography className="text-grey-700 mb-1.5">Period Payment Amount</Typography>
            <Tooltip title="How much you pay per period">
              <InformationCircleOutlined fontSize="small" className="ml-2" />
            </Tooltip>
          </Box>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            onWheel={(e) => e.preventDefault()}
            type="number"
            fullWidth
            value={offer.period_payment_amount}
            InputProps={{ startAdornment: <Typography className="mr-1">$</Typography> }}
            onChange={(e) => setOffer((offer) => ({ ...offer, period_payment_amount: e.target.value }))}
          />
        </Box>
        <Box>
          <Typography className="text-grey-700 mb-1.5">Buy Rate</Typography>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            error={!validateBuyRate(offer.buy_rate) && !!offer.buy_rate}
            helperText={!offer.buy_rate || validateBuyRate(offer.buy_rate) ? '' : 'Buy rate must be between 1 and 100'}
            onWheel={(e) => e.preventDefault()}
            type="number"
            fullWidth
            value={offer.buy_rate}
            onChange={(e) => setOffer((offer) => ({ ...offer, buy_rate: e.target.value }))}
          />
        </Box>
        <Box>
          <Typography className="text-grey-700 mb-1.5">Service Fee</Typography>
          <TextField
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              }
            }}
            onWheel={(e) => e.preventDefault()}
            type="number"
            fullWidth
            value={offer.service_fee}
            InputProps={{ startAdornment: <Typography className="mr-1">$</Typography> }}
            onChange={(e) => setOffer((offer) => ({ ...offer, service_fee: e.target.value }))}
          />
        </Box>
        <Box className="grid grid-cols-3 gap-6">
          <Box>
            <Typography className="text-grey-700 mb-1.5">Request Term Amount</Typography>
            <Autocomplete
              freeSolo
              options={termLengthOptions}
              value={offer.term_amount}
              onInputChange={(e, val) => {
                setOffer((offer) => ({ ...offer, term_amount: val || '' }))
              }}
              onChange={(_, val) => setOffer((offer) => ({ ...offer, term_amount: val || '' }))}
              renderInput={(params) => (
                <TextField
                  type="number"
                  {...params}
                  sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      display: 'none'
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Request Term Length</Typography>
            <TextField
              value={offer.term_length}
              fullWidth
              select
              onChange={(e) => setOffer((offer) => ({ ...offer, term_length: e.target.value as TermLength }))}
            >
              <MenuItem key={''} value={''} className="italic">
                unselected
              </MenuItem>
              {termLengths.map((length) => (
                <MenuItem key={length} value={length}>
                  {capitalize(length)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <Typography className="text-grey-700 mb-1.5">Payment Frequency</Typography>
            <TextField
              value={offer.term_frequency}
              fullWidth
              select
              onChange={(e) => setOffer((offer) => ({ ...offer, term_frequency: e.target.value as TermFrequency }))}
            >
              <MenuItem key={''} value={''} className="italic">
                unselected
              </MenuItem>
              {termFrequencies?.map((frequency) => (
                <MenuItem key={frequency} value={frequency}>
                  {capitalize(frequency)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <FilePicker files={files} setFiles={setFiles} />
        <Divider />
        <Box className="flex gap-2.5 justify-end">
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            disabled={Boolean(!offer.lead?.id && !offer.funder?.id)}
            onClick={handleSave}
            loading={mutation.isLoading}
            variant="contained"
          >
            Add Offer
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddOffer
