import { TransactionType } from '@prisma/client'
import { startOfQuarter, subDays, subMonths } from 'date-fns'
import { TransactionFilters } from '../views/accounting/transactions'

const getColorClasses = (type: TransactionType) => {
  if (type === 'COMMISSION') return 'text-warning-600 bg-warning-100'
  if (type === 'EXPENSE') return 'text-success-900 bg-success-50'
  if (type === 'SERVICE_FEE') return 'text-primary-600 bg-primary-50'
  if (type === 'USER_PAYMENT') return 'text-error-700 bg-error-50'
  if (type === 'CLAWBACK') return 'text-secondary-600 bg-secondary-50'
}

const getDateFilterValue = (val: string | undefined) => {
  const today = new Date()
  if (val === '1') return subDays(today, 30)
  if (val === '3') return startOfQuarter(today)
  if (val === '6') return subMonths(today, 6)
  return undefined
}

const getDateFilterLabel = (val: string | undefined) => {
  if (val === '1') return 'LAST 30 DAYS'
  if (val === '3') return 'THIS QUARTER'
  if (val === '6') return 'LAST 6 MONTHS'
  return 'ALL'
}

const formatBankAccount = (account: { name: string; account_number: string } | undefined) => {
  if (!account) return ''
  else return `${account.name} (...${account.account_number.slice(-4)})`
}

const getTransactionTypes = (type: 'ALL' | 'CREDIT' | 'DEBIT'): TransactionType[] => {
  if (type === 'DEBIT') return ['SERVICE_FEE', 'COMMISSION', 'BONUS']
  if (type === 'CREDIT') return ['CLAWBACK', 'EXPENSE', 'USER_PAYMENT']
  return []
}

const areArraysEqual = (a: TransactionType[], b: TransactionType[]) => {
  if (a.length !== b.length) return false
  const sortedA = [...a].sort()
  const sortedB = [...b].sort()
  return sortedA.every((value, index) => value === sortedB[index])
}

const getFilterCount = (filters: TransactionFilters): number => {
  return Object.entries(filters).reduce((count, [key, value]) => {
    if (key === 'dateTo') {
      return count
    }
    if (Array.isArray(value)) {
      return count + (value.length > 0 ? 1 : 0)
    }
    return count + (value !== undefined && value !== null ? 1 : 0)
  }, 0)
}

export { getColorClasses, getDateFilterValue, getDateFilterLabel, formatBankAccount, getTransactionTypes, areArraysEqual, getFilterCount }
