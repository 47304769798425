import { useMemo } from 'react'

export function useReassignmentAllowed(hasRelatedLeads: boolean, stageReassignmentType: string): [disabled: boolean, message?: string] {
  const [disabled, disabledMessage] = useMemo(() => {
    if (hasRelatedLeads) {
      return [true, 'Reassignment is not allowed for a linked lead']
  } else if (stageReassignmentType === 'NOT_REASSIGNABLE') {
      return [true, 'Reassignment is not allowed in the current pipeline stage']
    }

    return [false]
  }, [hasRelatedLeads, stageReassignmentType])

  return [disabled, disabledMessage]
}
