import { Box } from '@mui/material'
import { Partner } from '../icons/Partner'
import { Subsidiary } from '../icons/Subsidiary'
import clsx from 'clsx'

type Props = {
  type: 'subsidiary' | 'partner'
  includeText?: boolean
}

const UserBadge = (props: Props) => {
  const typeColors = {
    subsidiary: {
      bg: 'bg-[#F9F5FF]',
      border: 'border-[#E9D7FE]',
      text: 'text-[#724CCA]'
    },
    partner: {
      bg: 'bg-[#FFFAEA]',
      border: 'border-[#DAC997]',
      text: 'text-[#A28020]'
    }
  }

  const colors = typeColors[props.type]
  const className = `${clsx({
    'px-3': props.includeText
  })} p-1 ${colors.bg} border-[1px] border-solid ${colors.border} flex items-center justify-center h-fit rounded-[200px] gap-1 text_sm_medium ${
    colors.text
  }`

  if (props.type === 'partner') {
    return (
      <Box className={className}>
        <Partner />
        {props.includeText && 'Partner'}
      </Box>
    )
  }
  return (
    <Box className={className}>
      <Subsidiary />
      {props.includeText && 'Subsidiary'}
    </Box>
  )
}

export default UserBadge
