import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router'
import type { RouteObject } from 'react-router'
import DashboardLayout from './layouts/DashboardLayout'
import AuthLayout from './layouts/AuthLayout'
import LoadingScreen from './components/LoadingScreen'
import AuthGuard from './components/AuthGuard'
import GuestGuard from './components/GuestGuard'
import AdminGuard from './components/AdminGuard'
import Validation from './views/settings/Validation'
import AutoStage from './views/settings/AutoStage'

type RouteWithBreadCrumb = Omit<RouteObject, 'children'> & {
  crumb?: React.JSX.Element
  children?: RouteWithBreadCrumb[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: React.LazyExoticComponent<(props: any) => JSX.Element | null>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function (props: any) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    )
  }
const SubsidiaryBreadCrumb = Loadable(lazy(() => import('./components/breadcrumbs/SubsidiaryBreadCrumb')))
const NewDealBreadcrumb = Loadable(lazy(() => import('./components/breadcrumbs/NewDealBreadcrumb')))
const PipelineBreadCrumb = Loadable(lazy(() => import('./components/breadcrumbs/PipelineBreadCrumb')))
const BrokerBreadCrumb = Loadable(lazy(() => import('./components/breadcrumbs/BrokerBreadCrumb')))
const LeadBreadCrumb = Loadable(lazy(() => import('./components/breadcrumbs/LeadBreadCrumb')))
const NotFound = Loadable(lazy(() => import('./views/error/NotFoundView')))
const Login = Loadable(lazy(() => import('./views/userLogin/Login')))

const Analytics = Loadable(lazy(() => import('./views/analytics/analytics')))
const ExecuteSavedSearch = Loadable(lazy(() => import('./views/executeSavedSearch/ExecuteSavedSearch')))
const PipelineLeads = Loadable(lazy(() => import('./views/pipelines/pipelineLeads/index')))
const PipelineLead = Loadable(lazy(() => import('./views/pipelines/leadDetails/index')))
const Tasks = Loadable(lazy(() => import('./views/tasks/index')))
const ContactsAndCompanies = Loadable(lazy(() => import('./views/contactsAndCompanies/index')))
const Offers = Loadable(lazy(() => import('./views/offers/index')))
const Funders = Loadable(lazy(() => import('./views/funders/index')))
const SavedSearchList = Loadable(lazy(() => import('./views/savedSearchList/SavedSearchList')))
const Notifications = Loadable(lazy(() => import('./views/notifications/index')))
const Profile = Loadable(lazy(() => import('./views/profile/index')))
const Users = Loadable(lazy(() => import('./views/settings/Users')))
const Pipelines = Loadable(lazy(() => import('./views/settings/Pipelines')))
const AddOfferFunderView = Loadable(lazy(() => import('./views/offers/AddOfferFunderView')))
const DeclineOfferFunderView = Loadable(lazy(() => import('./views/offers/DeclineOfferFunderView')))
const Export = Loadable(lazy(() => import('./views/settings/Export')))
const PowerBiDashboard = Loadable(lazy(() => import('./views/powerbi/dashboard')))
const SentItems = Loadable(lazy(() => import('./views/mail/SentItems')))
const Inbox = Loadable(lazy(() => import('./views/mail/Inbox')))
const PowerBiGenerateReport = Loadable(lazy(() => import('./views/powerbi/generateReport')))
const BrowsePowerBiReports = Loadable(lazy(() => import('./views/powerbi/browserReports')))
const Home = Loadable(lazy(() => import('./views/home/index')))
const Roles = Loadable(lazy(() => import('./views/settings/Roles')))
const Accounting = Loadable(lazy(() => import('./views/settings/Accounting')))
const ShopDeals = Loadable(lazy(() => import('./views/shopDeals')))
const Expenses = Loadable(lazy(() => import('./views/accounting/expenses')))
const NewDeals = Loadable(lazy(() => import('./views/accounting/newDeals')))
const NewDeal = Loadable(lazy(() => import('./views/accounting/newDeals/newDeal')))
const FunderOnBoarding = Loadable(lazy(() => import('./views/funderOnBoarding/index')))
const Invoices = Loadable(lazy(() => import('./views/accounting/invoices')))
const BrokerDetails = Loadable(lazy(() => import('./views/accounting/brokers/brokerDetails')))
const Brokers = Loadable(lazy(() => import('./views/accounting/brokers/brokerList')))
const Transactions = Loadable(lazy(() => import('./views/accounting/transactions')))
const Partners = Loadable(lazy(() => import('./views/accounting/partners')))
const Commissions = Loadable(lazy(() => import('./views/accounting/commissions')))
const FundersAccounting = Loadable(lazy(() => import('./views/accounting/funders')))
const LazyCrumb = Loadable(lazy(() => import('./components/breadcrumbs/Crumb')))

const routesWithBreadCrumbs: RouteWithBreadCrumb[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/home" replace />
      },
      {
        path: 'home',
        crumb: <LazyCrumb key="home" text="Home" />,
        element: <Home />
      },
      {
        path: 'profile',
        crumb: <LazyCrumb key="profile" text="Profile" />,
        element: <Profile />
      },
      {
        path: 'notifications',
        crumb: <LazyCrumb key="notifications" text="Notifications" />,
        element: <Notifications />
      },
      {
        path: 'subsidiary',
        crumb: <LazyCrumb key="subsidiary" text="Subsidiaries" />,
        children: [
          {
            path: ':subsidiaryId',
            crumb: <SubsidiaryBreadCrumb key="subsidiary" />,
            children: [
              {
                path: 'pipeline',
                crumb: <LazyCrumb key="pipelines" text="Pipelines" />,
                children: [
                  {
                    path: ':pipelineId',
                    crumb: <PipelineBreadCrumb key="pipeline" />,
                    children: [
                      {
                        path: 'leads/view/:viewId',
                        element: <PipelineLeads />
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/pipeline',
        crumb: <PipelineBreadCrumb key="pipeline" />,
        children: [
          {
            path: ':pipelineId/lead/:leadId/:offerId?',
            element: <PipelineLead />,
            crumb: <LeadBreadCrumb key="lead" />
          }
        ]
      },

      {
        path: 'tasks',
        element: <Tasks />,
        crumb: <LazyCrumb key="tasks" path="/tasks" text="Tasks" />
      },
      {
        path: 'contacts/:contactId?',
        element: <ContactsAndCompanies />,
        crumb: <LazyCrumb key="contacts" path="/contacts" text="Contacts" />
      },
      {
        path: 'companies/:companyId?',
        element: <ContactsAndCompanies />,
        crumb: <LazyCrumb key="companies" path="/companies" text="Companies" />
      },
      {
        path: 'offers/:offerId?',
        element: <Offers />,
        crumb: <LazyCrumb key="offers" path="/offers" text="Offers" />
      },
      {
        path: 'funders/:funderId?',
        element: <Funders />,
        crumb: <LazyCrumb key="funders" path="/funders" text="Funders" />
      },
      {
        path: 'mail/inbox',
        element: <Inbox />,
        crumb: <LazyCrumb key="inbox" path="/mail/inbox" text="Inbox" />
      },
      {
        path: 'mail/sentItems',
        element: <SentItems />,
        crumb: <LazyCrumb key="sent" path="/mail/sentItems" text="Sent Items" />
      },
      {
        path: '/shop-deal-drafts/:leadId?/:pipelineId?',
        element: <ShopDeals />,
        crumb: <LazyCrumb key="drafts" path="/shop-deal-drafts" text="Shop Deals" />
      },
      {
        path: '/accounting',
        crumb: <LazyCrumb key="accounting" path="/accounting" text="Dashboard" />,
        children: [
          { path: 'expenses', element: <Expenses />, crumb: <LazyCrumb key="expenses" path="/accounting/expenses" text="Expenses" /> },
          { path: 'funders', element: <FundersAccounting />, crumb: <LazyCrumb key="funders" path="/accounting/funders" text="Funders" /> },
          { path: 'commissions', element: <Commissions />, crumb: <LazyCrumb key="commissions" path="/accounting/commissions" text="Commissions" /> },
          { path: 'partners', element: <Partners />, crumb: <LazyCrumb key="partners" path="" text="Partner / Subsidiary" /> },
          { path: 'invoices/:invoiceId?', element: <Invoices />, crumb: <LazyCrumb key="invoices" path="/accounting/invoices" text="Invoices" /> },
          {
            path: 'brokers',
            crumb: <LazyCrumb key="brokers" path="/accounting/brokers" text="Brokers" />,
            children: [
              { path: '', element: <Brokers /> },
              {
                path: ':userId',
                element: <BrokerDetails />,
                crumb: <BrokerBreadCrumb key="brokerDetails" />
              }
            ]
          },
          {
            path: 'broker/:userId',
            element: <BrokerDetails />,
            crumb: <BrokerBreadCrumb key="brokerDetails" />
          },
          {
            path: '/accounting/new-deals',
            crumb: <LazyCrumb key="newDeals" path="/accounting/new-deals" text="New Deals" />,
            children: [
              { path: '', element: <NewDeals /> },
              { path: ':offerId', element: <NewDeal />, crumb: <NewDealBreadcrumb key="newDeal" /> }
            ]
          },
          {
            path: '/accounting/transactions',
            element: <Transactions />,
            crumb: <LazyCrumb key="transactions" path="/accounting-transactions" text="Transactions" />
          }
        ]
      }
    ]
  },
  {
    path: '/analytics',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '/analytics/powerbi',
        element: <PowerBiDashboard />,
        crumb: <LazyCrumb key="powerbiDashboard" path="/analytics/powerbi" text="Dashboard" />
      },
      {
        path: '/analytics/browse',
        element: <BrowsePowerBiReports />,
        crumb: <LazyCrumb key="powerbiBrowse" path="/analytics/browse" text="Reports" />
      },
      {
        path: '/analytics/generate',
        element: <PowerBiGenerateReport />,
        crumb: <LazyCrumb key="powerbiGenerate" path="/analytics/generate" text="Generate Report" />
      },
      {
        path: '/analytics/dashboard',
        element: <Analytics />
      },
      {
        path: '/analytics/savedsearches',
        element: <SavedSearchList />
      },
      {
        path: '/analytics/executesearch/:id',
        element: <ExecuteSavedSearch />
      }
    ]
  },
  {
    path: '/settings',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="users" replace />
      },
      {
        path: 'pipelines',
        element: <Pipelines />,
        crumb: <LazyCrumb key="pipelineSettings" path="/settings/pipelines" text="Settings" />
      },
      {
        path: 'users/:userId?',
        element: <Users />,
        crumb: <LazyCrumb key="userSettings" path="/settings/users" text="Settings" />
      },
      {
        path: 'validation',
        element: <Validation />,
        crumb: <LazyCrumb key="validationSettings" path="/settings/validation" text="Settings" />
      },
      {
        path: 'auto-stage',
        element: <AutoStage />,
        crumb: <LazyCrumb key="autoStageSettings" path="/settings/auto-stage" text="Settings" />
      },
      {
        path: 'roles',
        element: <Roles />,
        crumb: <LazyCrumb key="roleSettings" path="/settings/roles" text="Settings" />
      },
      {
        path: 'accounting',
        element: <Accounting />,
        crumb: <LazyCrumb key="accountingSettings" path="/settings/accounting" text="Settings" />
      },
      {
        path: 'export',
        element: <Export />,
        crumb: <LazyCrumb key="exportSettings" path="/settings/export" text="Settings" />
      }
    ]
  },
  {
    path: '/add-offer',
    element: <AddOfferFunderView />
  },
  {
    path: '/decline-offer',
    element: <DeclineOfferFunderView />
  },
  { path: '/funder-on-boarding', element: <FunderOnBoarding /> }
]

export const dashboardRoutes = routesWithBreadCrumbs.map((route) => {
  const { crumb, ...rest } = route
  if (!route.children) {
    return rest
  }
  const children = route.children.map((child) => {
    const { crumb, ...rest } = child
    return rest
  })
  return { ...rest, children }
}) as RouteObject[]

const routes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  ...dashboardRoutes,
  {
    path: 'errors',
    element: <AuthLayout />,
    children: [
      {
        path: '/errors/404',
        element: <NotFound />
      }
    ]
  },

  {
    path: '/*',
    element: <Navigate to="/errors/404" replace />
  }
]

export default routes
export { routesWithBreadCrumbs, RouteWithBreadCrumb }
