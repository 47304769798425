import { trpc } from '../../../../core/trpc'
import { Box, Table, TableHead, TableRow, TableCell, Paper, TableContainer, TableBody, LinearProgress } from '@mui/material'
import { Fragment, useState } from 'react'
import { formatCurrency } from '../../../../utils'
import PayBrokerModal from '../../brokers/brokerList/components/PayBrokerModal'
import { groupBy } from 'remeda'
import { AccountingUserTypeObject } from '../../../../consts/accountingUserTypes'
import BrokerCommissionRow from './BrokerCommissionRow'
import Decimal from 'decimal.js'
import DocLink from '../../../../components/DocLink'

type BrokersCommissionTableProps = {
  offerId: string
  showPayUserButton?: boolean
}

const BrokersCommissionTable = ({ offerId, showPayUserButton = true }: BrokersCommissionTableProps) => {
  const [payUser, setPayUser] = useState<{ id: string; isProfit: boolean }>()
  const { data, isFetching, refetch } = trpc.brokersAccounting.getBrokersBreakdownByDeal.useQuery({ offerId })

  const groupedByType = data ? groupBy(data, (user) => user.user_type) : undefined
  const brokerCommissions = groupedByType?.[AccountingUserTypeObject.BROKER]?.flat()
  const teamLeadCommissions = groupedByType?.[AccountingUserTypeObject.TEAM_LEAD]?.flat()
  const isoCommissions = groupedByType?.[AccountingUserTypeObject.ISO]?.flat()
  const leadFeeCommissions = groupedByType?.[AccountingUserTypeObject.LEAD_FEE]?.flat()
  const adminFeeCommissions = groupedByType?.[AccountingUserTypeObject.ADMIN_FEE]?.flat()
  const subsidiaryCommissions = groupedByType?.[AccountingUserTypeObject.SUBSIDIARY]?.flat()
  const partnerCommissions = groupedByType?.[AccountingUserTypeObject.PARTNER]?.flat()

  const subTotalCommissions = {
    Brokers: brokerCommissions,
    'Team Leads': teamLeadCommissions,
    'Lead Fees': leadFeeCommissions,
    'Admin Fees': adminFeeCommissions,
    ISO: isoCommissions
  }
  const subTotal = data?.reduce((pre, cur) => {
    if (cur.user_type === 'PARTNER' || cur.user_type === 'SUBSIDIARY') return pre
    return pre.add(cur.total_amount)
  }, new Decimal(0))

  const cols = [
    { name: 'Users' },
    { name: 'Percentage' },
    {
      name: 'Total Broker Commission',
      docLink: '/admin/documentation/docs/accounting/field-explanation#commission-breakdown-total-broker-commission'
    },
    { name: 'Amount Received', docLink: '/admin/documentation/docs/accounting/field-explanation#commission-breakdown-amount-recieved' },
    { name: 'Amount Pending', docLink: '/admin/documentation/docs/accounting/field-explanation#commission-breakdown-amount-pending' }
  ]

  return (
    <TableContainer component={Paper}>
      {isFetching && <LinearProgress />}
      <Table size="small">
        <TableHead>
          <TableRow className="bg-grey-50">
            {cols.map((col) => (
              <TableCell className="text-grey-600 text_sm_medium" key={col.name}>
                {col.name}
                {col.docLink && <DocLink link={col.docLink} />}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(subTotalCommissions)
            .filter(([_, commissionGroup]) => Boolean(commissionGroup))
            .map(([key, commissionGroup]) => (
              <Fragment key={key}>
                <TableRow className="bg-secondary-50">
                  <TableCell colSpan={8} className="text-secondary-400 text_sm_medium py-2">
                    {key}
                  </TableCell>
                </TableRow>
                {commissionGroup?.map((broker) => (
                  <BrokerCommissionRow
                    showPayUserButton={showPayUserButton && broker.user_ids.length === 1}
                    key={broker.user_ids[0]}
                    row={broker}
                    handlePay={() => {
                      setPayUser({ id: broker.user_ids[0], isProfit: false })
                    }}
                  />
                ))}
              </Fragment>
            ))}
          <TableRow>
            <TableCell sx={{ paddingY: '8px' }} colSpan={8}>
              <Box className="bg-success-50 border-success-200 border-solid border-[1px] rounded-lg p-2 text-grey-900 text_md_medium">
                Total: {formatCurrency(subTotal?.toString() || 0)}
              </Box>
            </TableCell>
          </TableRow>
          {subsidiaryCommissions && (
            <>
              <TableRow className="bg-secondary-50">
                <TableCell colSpan={8} className="text-secondary-400 text_sm_medium py-2">
                  Subsidiary
                </TableCell>
              </TableRow>
              {subsidiaryCommissions.map((subsidiary) => (
                <BrokerCommissionRow
                  showPayUserButton={showPayUserButton && subsidiary.user_ids.length === 1}
                  key={subsidiary.user_ids[0]}
                  row={subsidiary}
                  handlePay={() => {
                    setPayUser({ id: subsidiary.user_ids[0], isProfit: true })
                  }}
                />
              ))}
            </>
          )}
          {partnerCommissions && (
            <Fragment>
              <TableRow className="bg-secondary-50">
                <TableCell colSpan={8} className="text-secondary-400 text_sm_medium py-2">
                  Partners
                </TableCell>
              </TableRow>
              {partnerCommissions.map((partner) => (
                <BrokerCommissionRow
                  showPayUserButton={showPayUserButton && partner.user_ids.length === 1}
                  key={partner.user_ids[0]}
                  row={partner}
                  handlePay={() => {
                    setPayUser({ id: partner.user_ids[0], isProfit: true })
                  }}
                />
              ))}
            </Fragment>
          )}
        </TableBody>
      </Table>
      {payUser && (
        <PayBrokerModal userId={payUser?.id} onClose={() => setPayUser(undefined)} refetch={refetch} isProfit={Boolean(payUser?.isProfit)} />
      )}
    </TableContainer>
  )
}

export default BrokersCommissionTable
